import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';
import { useDashboard } from '../../hooks/useDashboard'
import { XYPlot, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, LineSeries, LabelSeries, VerticalBarSeries, RadialChart, Hint, DiscreteColorLegend } from 'react-vis'
import { useAuth } from '../../hooks/auth'
import PageFooter from '../ui/PageFooter'
import {displayPeriod} from '../../utils/utils'
import '../../../node_modules/react-vis/dist/style.css'
import CompanyContext from '../../context/CompanyContext'
import PersonContext from '../../context/PersonContext'
import { usePersonList } from '../../hooks/usePersonList'
import { useCompanyList } from '../../hooks/useCompanyList'



const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    },
    title: { marginBottom: theme.spacing(2) }
}));

const DashboardPage = () => {
    const classes = useStyles()
    const [{ dashboardData, isLoading, userMessage, getDashboardData }] = useDashboard()
    const [{getCompanyList}] = useCompanyList({context:CompanyContext})
    const [{getPersonList}] = usePersonList({ context: PersonContext })


    const [hoverValue, setHoverValue] = useState(false)

    const auth = useAuth()
    const { currentPeriod } = auth.getUserData()
    const history = useHistory();


    const selectCompanies = (period, confirmed) => {
        console.log("Period: " + period + " Confrined: " + confirmed)
        history.push(`/company/list/status?period=${period}&confirmed=${confirmed}`)
    }


    let currentPeriodData = [{ angle: 1, name: "", color: "#bababa" }]
    if (dashboardData[currentPeriod]) {
        currentPeriodData = []
        currentPeriodData.push({ angle: dashboardData[currentPeriod].confirmed, name: "Confirmed", color: "#12939A" })
        currentPeriodData.push({ angle: dashboardData[currentPeriod].missing, name: "Missing", color: "#c5201d" })
    }

    let missingData = []
    let totalData = []
    if (dashboardData) {
        Object.entries(dashboardData).forEach(
            ([period, data]) => {
                missingData.push({ x: displayPeriod(period), y: data.missing, sort: period })
                totalData.push({ x: displayPeriod(period), y: data.confirmed, sort: period })
            })
            missingData.sort((a, b) => a.sort > b.sort ? 1 : -1);
            totalData.sort((a, b) => a.sort > b.sort ? 1 : -1);
        }

    // Do once on page load -> Get Dashboard Data
    useEffect(() => {
        getDashboardData()

        // If no company or person data - pre-emptively get them
        getCompanyList()
        getPersonList()
    }, []);


    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <Grid container className={classes.title}>
                    <Typography variant="h5" >Dashboard</Typography>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Grid container direction="column" justify="space-around" alignItems="center">
                            <XYPlot height={400} width={400} xType="ordinal" stackBy="y">
                                <HorizontalGridLines />
                                <XAxis />
                                <YAxis />
                                <VerticalBarSeries
                                    onValueClick={(d, e) => {
                                        console.log(d)
                                        console.log(e)
                                    }}
                                    color="#12939A"
                                    onValueClick={(d, e) => {selectCompanies(d.sort, true)}}
                                    data={totalData}
                                />
                                <VerticalBarSeries
                                    color="#c5201d"
                                    onValueClick={(d, e) => {selectCompanies(d.sort, false)}}
                                    data={missingData}
                                />
                            </XYPlot>
                            <Typography variant="h6" >Companies Reporting</Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Grid container direction="column" justify="space-around" alignItems="center">
                            <RadialChart
                                colorType={'literal'}
                                className={'donut-chart-example'}
                                innerRadius={80}
                                radius={140}
                                getLabel={d => { if (d.name === hoverValue.name) return hoverValue.name }}
                                data={currentPeriodData}
                                labelsRadiusMultiplier={1.5}
                                labelsStyle={{ fontSize: 20, fill: '#222' }}
                                showLabels
                                onValueClick={(d, e) => {selectCompanies(currentPeriod, d.name === "Confirmed")}}
                                onValueMouseOver={v => setHoverValue(v)}
                                onSeriesMouseOut={v => { setHoverValue(false) }}
                                width={400}
                                height={400}
                                padAngle={0.04}
                            >
                            </RadialChart>
                            <Typography variant="h6" >Status: {displayPeriod(currentPeriod)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <PageFooter userMessage={userMessage} spinner={isLoading} />

            </Paper>
        </Container>
    );
}

export default DashboardPage;