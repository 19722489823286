import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { useCompanyList } from '../../hooks/useCompanyList'
import CompanyContext from '../../context/CompanyContext'


const useStyles = makeStyles((theme) => ({
    container: { padding: theme.spacing(2) },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    textField: { paddingRight: "2rem", paddingLeft: "1rem" },
    title: { marginBottom: theme.spacing(2) },
    button: { display: "flex", alignItems: "center" },
    textOK: { color: "green" },
    textError: { color: "red" },
    userMessage: { marginTop: theme.spacing(2) }
}));


export default ({ personData = {}, updatePerson }) => {
    const classes = useStyles()
    const { title = "" } = personData
    const { companyID = "" } = personData
    const { isAngel = false } = personData
    const [{ companyData, isLoading, getCompanyList }] = useCompanyList({ context: CompanyContext })

    if (companyData.length === 0 && !isLoading)  {
        console.log("Get List")
        getCompanyList()
    }

    const getCompanyData = (id) => {
        const companyRecord = companyData.find((item) => item.id === id)
        return { "companyID": id, "companyName": companyRecord.name, "isAngel": false}
    }
    const updateAngel = (e) => {
        // A person can't be both an angel and in a company
        if (e.target.checked) {
            return { "isAngel": e.target.checked, "companyID": "", "companyName": ""}
        }
        return { "isAngel": e.target.checked}
    }

    // if (companyData.length === 0) {
    //     console.log("getCompanyList")
    //     getCompanyList()
    // }

    // console.log(companyData)

    const optionsList = () => {
        // If a list of companies exists
        if (companyData) {
            console.log("Options List")
            console.log(companyData)

            return companyData.map(company => (
                <option key={company.id} value={company.id}>
                    {company.name}
                </option>
            ))
            // } else if (companyID) {
            //     return (
            //         <option key={companyID} value={companyID}>
            //             "loading..."
            //         </option>
            //     )
        }
    }


    return (
        <div>
            <Grid container direction="row" align-items="flex-end">
                <Grid item md={2} xs={12}>
                    <FormControlLabel
                        label="Angel?"
                        labelPlacement="start"
                        control={
                            <Checkbox checked={isAngel} name="isAngel" color="primary"
                                onChange={(e) => { updatePerson(updateAngel(e)) }} />
                        }
                    />
                </Grid>
                <Grid item md={5} xs={12} className={classes.textField}>
                    <InputLabel shrink>Company</InputLabel>
                    <Select label="companyID" id="companyID" fullWidth 
                        defaultValue={companyID} value={companyID} 
                        onChange={(e) => { updatePerson(getCompanyData(e.target.value)) }}>
                        {companyData.map(company => (
                            <option key={company.id} value={company.id}>
                                {company.name}
                            </option>
                        ))}
                    </Select>

                </Grid>
                <Grid item md={5} xs={12} >
                    <TextField label="title" id="title" value={title} fullWidth
                        onChange={(e) => { updatePerson({ "title": e.target.value }) }} />
                </Grid>
            </Grid>
        </div>

    )
}

