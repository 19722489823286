import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NotesIcon from '@material-ui/icons/Notes'
import IconButton from '@material-ui/core/IconButton';
import EmailIcon from '@material-ui/icons/EmailOutlined'
import ChatIcon from '@material-ui/icons/ChatOutlined'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Link from '@material-ui/core/Link';
import InfoPopUp from '../ui/InfoPopUp'
import EmailHistoryPopUp from '../ui/EmailHistoryPopUp'
import ManualClosePopUp from '../ui/ManualClosePopUp'
import CommentsPopUp from '../ui/CommentsPopUp'
import { downloadFileAPI } from '../../requests/file'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useProvideCTA } from "../../hooks/cta"
import { useAuth } from '../../hooks/auth';

const useStyles = makeStyles((theme) => ({
    tableRow: { textDecoration: 'none' },
    textOK: { color: "green" },
    textError: { color: "red" }
}));


export default function Reporting({ reportingData = [], updateReporting }) {
    const classes = useStyles()
    const [showAskPopUp, setShowAskPopUp] = useState(false);
    const [askText, setAskText] = useState();
    const [askPeriod, setAskPeriod] = useState();
    const [showEmailHistoryPopUp, setShowEmailHistoryPopUp] = useState(false);
    const [emailHistory, setEmailHistory] = useState();
    const [showCommentsPopUp, setCommentsPopUp] = useState(false);
    const [showManualClosePopUp, setShowManualClosePopUp] = useState(false);
    const [manualCloseData, setManualCloseData] = useState(false);
    const [commentsData, setCommentsData] = useState();
    const [reportDownloading, setReportDownloading] = useState();
    const cta = useProvideCTA()
    const auth = useAuth()
    const { userID } = auth.getUserData()

    const hideAsk = () => {
        setShowAskPopUp(false)
    }
    const showAsk = (period, ask) => {
        setAskText(ask)
        setAskPeriod(period)
        setShowAskPopUp(true)
    }
    const showManualClose = ({period}) => {
        setManualCloseData({period})
        setShowManualClosePopUp(true)
    }

    const hideEmailHistory = () => {
        setShowEmailHistoryPopUp(false)
    }
    const showEmailHistory = (emailData) => {
        console.log(emailData)
        setEmailHistory(emailData)
        setShowEmailHistoryPopUp(true)
    }

    const hideComments = () => {
        setCommentsPopUp(false)
    }
    const showComments = (commentsData) => {
        console.log(commentsData)
        setCommentsData(commentsData)
        setCommentsPopUp(true)
    }

    const hideManualClose = (data) => {
        setManualCloseData()
        setShowManualClosePopUp(false)
        setReportDownloading("Updating Report Data...")
        if (data) {
            const { comment, period } = data
            console.log(userID)
            console.log(period)
            console.log(comment)
            updateReporting({ personID:userID, period, comment })
        }
    }

    const downloadFile = (report) => {
        console.log(report)
        console.log("Get here")

        const fileName = report.file.substr(report.file.lastIndexOf('/') + 1)
        setReportDownloading("Downloading Report...")
        downloadFileAPI({ location: report.location, file: report.file })
            .then((content) => {
                console.log(content.size);
                saveAs(content, fileName)
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setReportDownloading()
            })





    }

    return (
        <React.Fragment>
            <Table size="medium">
                <TableHead>
                    <TableRow>
                        <TableCell>Period</TableCell>
                        <TableCell>Completed</TableCell>
                        <TableCell>Completed On</TableCell>
                        <TableCell>Reporter</TableCell>
                        <TableCell>Uploaded</TableCell>
                        <TableCell>Notes</TableCell>
                        <TableCell>Request</TableCell>
                        <TableCell>Email</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reportingData.map((item) => (
                        <TableRow key={item.period} className={classes.tableRow}>
                            <TableCell>{item.period}</TableCell>
                            <TableCell>
                                <span className={item.completed ? classes.textOK : classes.textError}>
                                    {item.completed ? "Yes" : "No"}
                                    {!item.completed &&
                                        <IconButton color="default" size='small' onClick={() => showManualClose({ period: item.period })}>
                                            <CheckCircleOutlineIcon />
                                        </IconButton>}

                                </span>
                            </TableCell>
                            <TableCell>{item.timestamp}</TableCell>
                            <TableCell>{item.reporter}</TableCell>
                            <TableCell>
                                {item.reports && (item.reports.map((report, idx) => (
                                    <IconButton key={idx} color="inherit" size='small' onClick={() => downloadFile(report)}>
                                        <AttachFileIcon />
                                    </IconButton>
                                )))}
                                {!item.reports && "No"}
                            </TableCell>
                            <TableCell>
                                {item.comments ? (
                                    <IconButton color="inherit" size='small' onClick={() => showComments(item.comments)}>
                                        <NotesIcon />
                                    </IconButton>
                                ) : "No"}
                            </TableCell>
                            <TableCell>
                                {item.ask ? (
                                    <IconButton color="inherit" size='small' onClick={() => showAsk(item.period, item.ask)}>
                                        <ChatIcon />
                                    </IconButton>
                                ) : "No"}
                            </TableCell>
                            <TableCell>
                                {(item.email && item.email.length > 0) ? (
                                    <IconButton color="inherit" size='small' onClick={() => showEmailHistory(item.email)}>
                                        <EmailIcon />
                                    </IconButton>
                                ) : "No"}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Typography>{reportDownloading}</Typography>
            <InfoPopUp open={showAskPopUp} period={askPeriod} messageText={askText} handleClose={hideAsk} />
            <EmailHistoryPopUp open={showEmailHistoryPopUp} handleClose={hideEmailHistory} emailData={emailHistory} />
            <CommentsPopUp open={showCommentsPopUp} handleClose={hideComments} commentsData={commentsData} />
            <ManualClosePopUp open={showManualClosePopUp} data={manualCloseData} handleClose={hideManualClose} />
        </React.Fragment>
    );
}