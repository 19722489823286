const DOMAIN = "https://77gztusqbd.execute-api.us-east-1.amazonaws.com/"

const getCompanyListAPI = () => {
    const url = `${DOMAIN}company/list`

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);

    console.log(`Call Get Company List API: ${url}`)
    return fetch(url, {method: 'GET', headers: headers}).then((response) => {
        if (response.status === 401) {
            return {unauthorized:true}
        } 
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    });
};

const getCompanyReportStatusAPI = (period) => {
    const url = `${DOMAIN}company/list?period=${period}`

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);

    console.log(`Call Get Company Report status API: ${url}`)
    return fetch(url, {method: 'GET', headers: headers}).then((response) => {
        if (response.status === 401) {
            return {unauthorized:true}
        } 
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    });
};

const getCompanyReportStatusListAPI = ({period, confirmed}) => {
    let url = `${DOMAIN}company/list/status?period=${period}`
    if (confirmed != undefined){
        url += `&confirmed=${confirmed}`
    }

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);

    console.log(`Call Get Company Report Status List API: ${url}`)
    return fetch(url, {method: 'GET', headers: headers}).then((response) => {
        if (response.status === 401) {
            return {unauthorized:true}
        } 
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    });

}

const getCompanyEmailListAPI = (period) => {
    const url = `${DOMAIN}company/email?period=${period}`

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);

    console.log(`Call Get Company Report status API: ${url}`)
    return fetch(url, {method: 'GET', headers: headers}).then((response) => {
        if (response.status === 401) {
            return {unauthorized:true}
        } 
        return response.json()
    })
    .catch((error) => {
        console.log("Get here")
        return Promise.reject(error);
    })
}

const getCompanyAPI = ({id, timestamp}) => {
    console.log(`Company ID: ${id}`)
    let url = `${DOMAIN}company?id=${id}`
    if (timestamp) {
        url += `&timestamp=${timestamp}`
    }

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);

    console.log(`Call Get Company API: ${url}`)
    return fetch(url, {method: 'GET', headers: headers}).then((response) => {
        if (response.status === 204) {
            return {noChange:true}
        } 
        if (response.status === 401) {
            return {unauthorized:true}
        } 
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    });
}

const updateCompanyAPI = (companyData) => {
    const url = `${DOMAIN}company?id=${companyData.id}`

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);
    console.log(`Call Post Company Update API: ${url}`)

    return fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(companyData) })
    .then((response) => {
        if (response.ok) {
            return response.json()
        } else if (response.status === 401) {
            // Check for expired token - refresh login
            return { unauthorized: true }
        } else if (response.bodyUsed) {
            return response.json()
        } else {
            const errorMessage = 'Looks like there was a problem. Status Code: ' + response.status;
            console.log(errorMessage);
            return Promise.reject(errorMessage);
        }
    })
    .catch((error) => {
        return Promise.reject(error);
    });
};

const updateCompanyReportingAPI = ({companyID, personID, period, comment}) => {
    const url = `${DOMAIN}company/reporting?period=${period}&companyID=${companyID}&personID=${personID}&comment=${comment}`
    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);
    console.log(`Call Post Company Update API: ${url}`)

    return fetch(url, { method: 'GET', headers: headers})
    .then((response) => {
        if (response.ok) {
            return response.json()
        } else if (response.status === 401) {
            // Check for expired token - refresh login
            return { unauthorized: true }
        } else if (response.bodyUsed) {
            return response.json()
        } else {
            const errorMessage = 'Looks like there was a problem. Status Code: ' + response.status;
            console.log(errorMessage);
            return Promise.reject(errorMessage);
        }
    })
    .catch((error) => {
        return Promise.reject(error);
    });
};

const createCompanyAPI = (companyData) => {
    const url = `${DOMAIN}company`

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);
    console.log(`Call PUT Create Company API: ${url}`)

    return fetch(url, { method: 'PUT', headers: headers, body: JSON.stringify(companyData) })
    .then((response) => {
        if (response.ok) {
            return response.json()
        } else if (response.status === 401) {
            // Check for expired token - refresh login
            return { unauthorized: true }
        } else if (response.bodyUsed) {
            return response.json()
        } else {
            const errorMessage = 'Looks like there was a problem. Status Code: ' + response.status;
            console.log(errorMessage);
            return Promise.reject(errorMessage);
        }
    })
    .catch((error) => {
        return Promise.reject(error);
    });
};


export {getCompanyListAPI, getCompanyAPI, updateCompanyAPI, createCompanyAPI, getCompanyReportStatusListAPI, getCompanyEmailListAPI, updateCompanyReportingAPI}
