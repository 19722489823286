import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles((theme) => ({
    textOK: { color: "green" },
    textError: { color: "red" },
    userMessage: { marginTop: theme.spacing(2) },
    spinner: { paddingTop: theme.spacing(2), display: 'flex', justifyContent: 'center' }
}));


export default ({ userMessage, spinner }) => {
    const classes = useStyles()

    let displayMessage = false
    if (userMessage && userMessage.text && userMessage.text.length > 0) {
        displayMessage = true
    }

    return (
        <React.Fragment>

            {displayMessage ? (<div className={classes.userMessage}>
                <Typography variant="h5">
                    <span className={userMessage.isError ? classes.textError : classes.textOK}>
                        {userMessage.text}
                    </span>
                </Typography>
            </div>) : null}

            {spinner ? (<div className={classes.spinner}>
                <CircularProgress />
            </div>) : null}

        </React.Fragment>
    )
}


