import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import NotesIcon from '@material-ui/icons/Notes'
import EmailIcon from '@material-ui/icons/Email'
import CommentsPopUp from '../ui/CommentsPopUp'
import EmailHistoryPopUp from '../ui/EmailHistoryPopUp'


const headerCells = [
    { id: 'photoURL', label: 'Photo' },
    { id: 'givenName', label: 'Member Name' },
    { id: 'email', label: 'Email' },
    { id: 'linkedIn', label: 'linkedIn' },
    { id: 'experience', label: 'Experience' },
    { id: 'interests1', label: 'Interests 1' },
    { id: 'interests2', label: 'Interests 2' },
    { id: 'bio', label: 'Bio' },
]

const useStyles = makeStyles((theme) => ({
    tableRow: { textDecoration: 'none' },
    textOK: { color: "green" },
    textError: { color: "red" },
    visuallyHidden: {
        border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden',
        padding: 0, position: 'absolute', top: 20, width: 1,
    }
}));


const TableHeader = (props) => {
    const { classes, order, orderBy, onRequestSort, numSelected, rowCount, selectMember } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(property);
    }

    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    <Checkbox color="primary"
                        onChange={() => { selectMember() }}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount} />
                </TableCell>
                {headerCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}




const MemberEmailTable = ({ memberData = [], selectMember, selections, sortData }) => {
    const classes = useStyles()
    const [isAsc, setIsAsc] = useState(false)
    const [orderBy, setOrderBy] = useState()
    const history = useHistory();


    const selectRow = (id) => {
        history.push(`/person/${id}`)
    }

    const handleRequestSort = (property) => {
        const newOrderAsc = orderBy === property && !isAsc
        setIsAsc(newOrderAsc)
        setOrderBy(property)
        sortData({ orderBy: property, isAsc: newOrderAsc })
    }

    return (
        <React.Fragment>
            <Table size="small">
                <TableHeader
                    classes={classes}
                    order={isAsc ? "asc" : "desc"}
                    orderBy={orderBy}
                    selectMember={selectMember}
                    onRequestSort={handleRequestSort} />
                <TableBody>
                    {memberData.map((item) => (
                        <TableRow hover key={item.id} className={classes.tableRow} onClick={() => { selectRow(item.id) }}>
                            <TableCell>
                                <Checkbox color="primary"
                                    onClick={(e) => { e.stopPropagation() }}
                                    onChange={() => { selectMember(item.id) }}
                                    checked={selections.includes(item.id)} />
                            </TableCell>
                            <TableCell>
                                <Avatar alt={item.name} src={item.photoURL} />
                            </TableCell>
                            <TableCell>{item.givenName} {item.familyName}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>
                                <span className={item.linkedIn ? classes.textOK : classes.textError}>
                                    {item.linkedIn ? "Yes" : "Missing"}
                                </span>
                            </TableCell>
                            <TableCell>
                                <span className={item.experience ? "" : classes.textError}>
                                    {item.experience ? item.experience.length : "0"}
                                </span>
                            </TableCell>
                            <TableCell>
                                <span className={item.interests1 ? "" : classes.textError}>
                                    {item.interests1 ? item.interests1.length : "0"}
                                </span>
                            </TableCell>
                            <TableCell>
                                <span className={item.interests2 ? "" : classes.textError}>
                                    {item.interests2 ? item.interests2.length : "0"}
                                </span>
                            </TableCell>
                            <TableCell>
                                <span className={item.bio ? classes.textOK : classes.textError}>
                                    {item.bio ? "Yes" : "Missing"}
                                </span>
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}

export default MemberEmailTable