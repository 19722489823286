import React from 'react';

const HelpPage = () => {
    return (
        <div>
            <h1>Help Page</h1>
            <p>Some Help Goes Here</p>
        </div>
    );
}

export default HelpPage;