import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useAuth } from '../../hooks/auth';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Message } from '@material-ui/icons';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const useStyles = makeStyles((theme) => ({
    textError: { color: "red" },
    textOK: { color: "green" },
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});




const ChangePasswordModal = ({ userName, session, open, onClose }) => {
    const classes = useStyles()
    const auth = useAuth()
    const [error, setError] = useState()
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")

    const validatePasswords = () => {
        setError()
        console.log("validating passwords: " + password1 + " " + password2)
        if (password1 != password2) {
            setError("Passwords must match")
            return
        }
        auth.changePassword({ userName, password:password1, session }, changePasswordCallback)
    }

    const changePasswordCallback = ({ success, errorMessage }) => {
        if (success) {
            console.log("Login OK!");
            handleClose("Your password was changed successfully")
        } else {
            setError(errorMessage)
        }
    }
    const handleClose = (message) => {
        onClose(message ? message : "")
    }


    return (
        <div>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle id="change password modal" onClose={handleClose}>
                    <Typography component="h1" variant="h5">Password Change Required</Typography>
        </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        In order to login you need to change your current password. Please enter your new password below:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        value={password1}
                        id="password1"
                        label="New Password"
                        type="password"
                        onChange={(e) => setPassword1(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        value={password2}
                        id="password2"
                        label="Repeat Password"
                        type="password"
                        onChange={(e) => setPassword2(e.target.value)}
                        fullWidth
                    />
                    <Typography className={classes.textError}> {error}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={validatePasswords} color="primary" disabled={password1.length == 0 || password2.length == 0} >
                        Change
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ChangePasswordModal