import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import PersonList from '../lists/PersonList'
import { usePersonList } from '../../hooks/usePersonList'
import PersonContext from '../../context/PersonContext'
import PageFooter from '../ui/PageFooter'

const useStyles = makeStyles((theme) => ({
    container: { padding: theme.spacing(2) },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    }
}));

const PersonListPage = () => {
    const classes = useStyles()
    const history = useHistory()
    const [{ personList, isLoading, userMessage, getPersonList, sortPersonList }] = usePersonList({ context: PersonContext })
    console.log("personList Page")
    console.log(personList)
    console.log(userMessage)

    // Do once on page load
    useEffect(() => {
        console.log("Calling UseEffect...")
        getPersonList()
    }, [])

    const newPerson = () => {
        console.log("New Person")
        history.push(`/person/new`)
    }
    
    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.title}>
                    <Grid item>
                        <Typography variant="h5">People List</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={newPerson}>New Person</Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={12}>
                        <PersonList personData={personList} sortData={sortPersonList} />
                    </Grid>
                </Grid>
                <PageFooter userMessage={userMessage} spinner={isLoading} />
            </Paper>
        </Container>
    )
}

export default PersonListPage;