import React, {useState} from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'

const headerCells = [
    { id: 'name', label: 'Company Name' },
    { id: 'location', label: 'Location' },
    { id: 'contact', label: 'Contact' },
    { id: 'nyaContact', label: 'NYA Contact' },
    { id: 'lastReport', label: 'Last Report' },
    { id: 'status', label: 'Status' },
  ]
  
const useStyles = makeStyles((theme) => ({
    textOK: { color: "green" },
    textError: { color: "red" },
    visuallyHidden: { border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden',
                    padding: 0, position: 'absolute', top: 20, width: 1,},
}))

const TableHeader = (props) => {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(property)
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell/>
          {headerCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
}

const BasicCompanyList = ({ companyList = [], sortData }) => {
    const classes = useStyles()
    const history = useHistory();
    const [isAsc, setIsAsc] = useState(false)
    const [orderBy, setOrderBy] = useState()
    const selectRow = (id) => {
        history.push(`/company/${id}`)
    }

    const handleRequestSort = (property) => {
        const newOrderAsc = orderBy === property && !isAsc
        setIsAsc(newOrderAsc)
        setOrderBy(property)
        sortData({orderBy:property, isAsc:newOrderAsc})
    }

    return (
        <React.Fragment>
            <Table size="medium">
                <TableHeader
                    classes={classes}
                    order={isAsc ? "asc" : "desc"}
                    orderBy={orderBy}                    
                    onRequestSort={handleRequestSort} />
                <TableBody>
                    {companyList.map((item) => (
                        <TableRow hover key={item.id} onClick={() => { selectRow(item.id) }} >
                            <TableCell>
                                {item.logo ? (<Avatar src={item.logo} alt={item.name} variant="rounded" />)
                                    : (<Avatar variant="rounded">
                                        <ImageIcon />
                                    </Avatar>)}
                            </TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.location}</TableCell>
                            <TableCell>{item.contact}</TableCell>
                            <TableCell>{item.nyaContact}</TableCell>
                            <TableCell>
                                <span className={item.lastReport != 'none' ? classes.textOK : classes.textError}>
                                    {item.lastReport}
                                </span>
                            </TableCell>
                            <TableCell>{item.status}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}

export default BasicCompanyList