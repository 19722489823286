import {useState} from 'react'
import { getUserListAPI, resetUserPasswordAPI, createNewUserAPI} from '../requests/user'
import { useAuth } from '../hooks/auth';

// Hook for all company Access
export const useUsers = () => {
    const [userList, setUserList] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [userMessage, setUserMessage] = useState()
    const auth = useAuth()

    // Return company data from context (if available) or from server
    const getUserList = () => {
        setIsLoading(true)
        getUserListAPI()
            .then((response) => {
                setIsLoading(false)
                console.log(response)
                if ("unauthorized" in response) {
                    setUserMessage({ isError: true, text: "Unauthorized" })
                } else {
                    if (response.success) {
                        // Make into array
                        setUserList(response.data)
                        setUserMessage({ isError: false, text: "User Data Retrieved OK" })
                        setTimeout(() => { setUserMessage({}) }, 1500)
                    } else {
                        setUserMessage({ isError: true, text: response.errorMessage })
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
                setUserMessage({ isError: false, text: error })
            })
    }

    const createNewUser = ({user, email, personID}) => {
        setIsLoading(true)
        createNewUserAPI({user, email, personID})
            .then((response) => {
                setIsLoading(false)
                console.log(response)
                if ("unauthorized" in response) {
                    setUserMessage({ isError: true, text: "Unauthorized" })
                } else {
                    if (response.success) {
                        setUserMessage({ isError: false, text: "New User Created" })
                        setTimeout(() => { setUserMessage({}) }, 1500)
                    } else {
                        setUserMessage({ isError: true, text: response.errorMessage })
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
                setUserMessage({ isError: false, text: error })
            })
    }

    const resetUserPassword = ({email}) => {
        setIsLoading(true)
        resetUserPasswordAPI({email})
            .then((response) => {
                setIsLoading(false)
                console.log(response)
                if ("unauthorized" in response) {
                    setUserMessage({ isError: true, text: "Unauthorized" })
                } else {
                    if (response.success) {
                        setUserMessage({ isError: false, text: `Password reset for user: ${email}` })
                        setTimeout(() => { setUserMessage({}) }, 1500)
                    } else {
                        setUserMessage({ isError: true, text: response.errorMessage })
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
                setUserMessage({ isError: false, text: error })
            })
    }


    return [{userList, isLoading, userMessage, getUserList, createNewUser, resetUserPassword}]
}
