import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useAuth } from '../../hooks/auth';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Message } from '@material-ui/icons';
import PersonContext from '../../context/PersonContext'
import { usePersonList } from '../../hooks/usePersonList'



const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    or: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    }
});
const useStyles = makeStyles((theme) => ({
    textError: { color: "red" },
    textOK: { color: "green" },
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});




const NewUserModal = ({ open, onClose }) => {
    const classes = useStyles()
    const auth = useAuth()
    const [{ personList, getPersonList }] = usePersonList({ context: PersonContext })

    const [error, setError] = useState()
    const [userEmail, setUserEmail] = useState("")
    const [emailHelper, setEmailHelper] = useState()
    const [userName, setUserName] = useState("")
    const [personID, setPersonID] = useState("")

    useEffect(() => {
        getPersonList()
    }, []);

    const exitModal = () => {
        setError()
        setEmailHelper()
        setPersonID("")
        setUserEmail("")
        setUserName("")
        onClose({})
    }

    const validateInput = () => {
        setError("")
        if (personID.length > 0) {
            if (userName.length >0 || userEmail.length > 0) {
                setError("Please either choose a person or enter a name and an email address.")
                return
            }
        }  else {
            if (userName.length ===  0 || userEmail.length === 0) {
                setError("Please enter a name and an email address.")
                return
            }
            if (!!emailHelper) {
                setError("Please enter a valid email address.")
                return
            }
        }
        // All OK leave and create user in main Component
        setError()
        setEmailHelper()
        setPersonID("")
        setUserEmail("")
        setUserName("")
        onClose({personID, userEmail, userName})
    }

    const updateEmail = (email) => {
        setUserEmail(email)
        if (email.length === 0) {
            setEmailHelper()
            return
        }
        const isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(email)
        if (!isValid) {
            setEmailHelper("Invalid email")
        } else {
            setEmailHelper()
        }
    }


    return (
        <div>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle id="change password modal" onClose={exitModal}>
                    <Typography component="h1" variant="h5">Create New User</Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Please specify either an existing person, or enter a name and email in order to create a user for a new person.
                    </DialogContentText>
                    <InputLabel shrink>person</InputLabel>
                    <Select fullWidth onChange={(e) => { setPersonID(e.target.value) }}
                        value={personID}>
                        {personList.map(person => {
                            //  Check Person is not already a user and has an email
                            if (person.email && !person.lastLogin) {
                                return (
                                    <option key={person.id} value={person.id}>
                                        {person.givenName} {person.familyName} - {person.email}
                                    </option>
                                )
                            }
                        })}
                    </Select>
                    <div className={classes.or}>
                        <Typography > or </Typography>
                    </div>

                    <TextField
                        autoFocus
                        value={userName}
                        id="userName"
                        label="User Name"
                        type="text"
                        onChange={(e) => setUserName(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        value={userEmail}
                        id="email"
                        label="User's Email"
                        type="email"
                        error={!!emailHelper}
                        helperText={emailHelper}
                        onChange={(e) => updateEmail(e.target.value)}
                        fullWidth
                    />
                    <Typography className={classes.textError}> {error}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={exitModal}>
                        Cancel
                    </Button>
                    <Button onClick={validateInput} color="primary" disabled={personID.length == 0 && userEmail.length == 0} >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default NewUserModal