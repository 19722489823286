import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import DashboardPage from '../components/home/DashboardPage'
import HelpPage from '../components/Help'
import NotFoundPage from '../components/NotFound'
import LoginPage from '../components/Login'
import { createBrowserHistory } from 'history'
import SwimRoute from './SwimRoute'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import CompanyPage from '../components/company/CompanyPage'
import CompanyListPage from '../components/company/CompanyListPage'
import CompanyReportStatusPage from '../components/company/CompanyReportStatusPage'
import PersonPage from '../components/person/PersonPage'
import PersonListPage from '../components/person/PersonListPage'
import EmailTextPage from '../components/email/EmailTextPage'
import SendEmailPage from '../components/email/SendEmailPage'
import SendMemberEmailPage from '../components/email/SendMemberEmailPage'
import EmailValidationPage from '../components/email/EmailValidationPage'
import ReportingAdminPage from '../components/admin/ReportingAdminPage'
import CtaConfirmationPage from '../components/cta/CtaConfirmationPage'
import CtaReportUploadPage from '../components/cta/CtaReportUploadPage'
import CtaContactDetailsPage from '../components/cta/CtaContactDetailsPage'
import CtaCommentPage from '../components/cta/CtaCommentPage'
import CtaPersonProfileUpdatePage from '../components/cta/CtaPersonProfileUpdatePage'
import SwimPage from '../components/swim/SwimPage'

import EmailTemplateListPage from '../components/email/EmailTemplateListPage'
import UserProfile from '../components/person/UserProfile'
import UserAdminPage from '../components/admin/UserAdminPage'
import PasswordResetPage from '../components/PasswordResetPage'

export const history = createBrowserHistory()

const AppRouter = () => (
    <BrowserRouter>
        <Switch>
            <PublicRoute path="/" component={LoginPage} exact={true} />
            <SwimRoute path="/swim" component={SwimPage} exact={true} />
            <PublicRoute path="/passwordReset" component={PasswordResetPage} />
            <Route path="/cta1/:companyID" component={CtaConfirmationPage} />
            <Route path="/cta2/:companyID" component={CtaReportUploadPage} />
            <Route path="/cta3/:companyID" component={CtaCommentPage} />
            <Route path="/cta4/:companyID" component={CtaContactDetailsPage} />
            <Route path="/cta5/:personID" component={CtaPersonProfileUpdatePage} />
            <PrivateRoute path="/company/list/status" component={CompanyReportStatusPage} />
            <PrivateRoute path="/company/list" component={CompanyListPage} />
            <PrivateRoute path="/company/:id" component={CompanyPage} />
            <PrivateRoute path="/person/list" component={PersonListPage} />
            <PrivateRoute path="/person/:id" component={PersonPage} />
            <PrivateRoute path="/email/send" component={SendEmailPage} />
            <PrivateRoute path="/email/member/send" component={SendMemberEmailPage} />
            <PrivateRoute path="/email/validate" component={EmailValidationPage} />
            <PrivateRoute path="/email/templates" component={EmailTemplateListPage} />
            <PrivateRoute path="/email/:id" component={EmailTextPage} />
            <PrivateRoute path="/dashboard" component={DashboardPage} />
            <PrivateRoute path="/admin" component={ReportingAdminPage} />
            <PrivateRoute path="/help" component={HelpPage} />
            <PrivateRoute path="/user" component={UserProfile} />
            <PrivateRoute path="/users" component={UserAdminPage} />
            <Route component={NotFoundPage} />
        </Switch>
    </BrowserRouter>
)

export default AppRouter
