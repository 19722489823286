import { useState, useContext } from 'react'
import { getCompanyAPI, updateCompanyAPI, createCompanyAPI, updateCompanyReportingAPI } from '../requests/company'
import { uploadFileAPI } from '../requests/file'
import { useAuth } from '../hooks/auth';

// Hook for all company Access
export const useCompany = ({ context }) => {
    const [companyData, setCompanyData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const { company, dispatchCompany } = useContext(context)
    const [userMessage, setUserMessage] = useState()
    const auth = useAuth()

    // Update local company state
    const updateCompany = (data) => {
        const updatedCompanyData = { ...companyData, ...data }
        setCompanyData(updatedCompanyData)
    }

    // Save Company Data to context and server
    const saveCompany = () => {
        setIsLoading(true)
        if (companyData.id) {
            console.log("Calling Company update:")
            console.log(companyData)
            updateCompanyAPI(companyData)
                .then((response) => {
                    setIsLoading(false)
                    console.log(response)
                    if ("unauthorized" in response) {
                        setUserMessage({ isError: true, text: "Unauthorized" })
                    } else {
                        if (response.success) {
                            dispatchCompany({ type: 'UPDATE_COMPANY', data: response.data })
                        } else {
                            setUserMessage({ isError: true, text: response.errorMessage })
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false)
                    console.log(error)
                    setUserMessage({ isError: false, text: error })
                })
        } else {
            console.log("Calling Company create:")
            console.log(companyData)
            createCompanyAPI(companyData)
                .then((response) => {
                    setIsLoading(false)
                    console.log(response)
                    if ("unauthorized" in response) {
                        setUserMessage({ isError: true, text: "Unauthorized" })
                    } else {
                        if (response.success) {
                            // setCompanyData(response.data)
                            // setUserMessage({ isError: false, text: "Data saved successfully" })
                            // setTimeout(() => { setUserMessage({}) }, 1500)
                            dispatchCompany({ type: 'NEW_COMPANY', data: response.data })
                        } else {
                            setUserMessage({ isError: true, text: response.errorMessage })
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false)
                    console.log(error)
                    setUserMessage({ isError: false, text: error })
                })
        }
    }


    // Update company reporting data
    const updateCompanyReporting = ({personID, comment, period}) => {
        console.log("Get here")
        console.log(companyData.id)
        console.log(personID)
        console.log(comment)
        console.log(period)
        


        setIsLoading(true)
        updateCompanyReportingAPI({companyID:companyData.id, personID, comment, period})
            .then((response) => {
                console.log(response)
                setIsLoading(false)
                if ("unauthorized" in response) {
                    setUserMessage({ isError: true, text: "unauthorized" })
                } else {
                    if (response.success) {
                        dispatchCompany({ type: 'UPDATE_COMPANY', data: { ...companyData, ...response.data }})
                    } else {
                        setUserMessage({ isError: true, text: response.errorMessage })
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                setIsLoading(false)
                setUserMessage({ isError: true, text: error })
            })
    }
    


    // Return company data from context (if available) or from server
    const getCompanyData = (id) => {
        // If we have cached company data - show it
        const selectedCompany = company.data.find((item) => (item.id === id))
        let timestamp = undefined
        if (selectedCompany) {
            setCompanyData(selectedCompany)
            timestamp = selectedCompany.updatedOn
        }
        // And get latest data from server
        setIsLoading(true)
        getCompanyAPI({id, timestamp})
            .then((response) => {
                console.log(response)
                setIsLoading(false)
                if ("unauthorized" in response) {
                    setUserMessage({ isError: true, text: "unauthorized" })
                } else if ("noChange" in response) {
                    return
                } else {
                    if (response.success) {
                        dispatchCompany({ type: 'UPDATE_COMPANY', data: response.data })
                    } else {
                        setUserMessage({ isError: true, text: response.errorMessage })
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                setIsLoading(false)
                setUserMessage({ isError: true, text: error })
            })
    }

    const uploadLogo = ({ fileName, file }) => {
        setIsLoading(true)
        uploadFileAPI({ fileName, file, path: "images/logos" })
            .then((response) => {
                setIsLoading(false)
                console.log(response)
                if ("unauthorized" in response) {
                    setUserMessage({ success: false, errorMessage: "Unauthorized" })
                } else {
                    if (response.success) {
                        const updatedCompanyRecord = { ...companyData, logo: response.fileName }
                        console.log(updatedCompanyRecord)
                        setCompanyData(updatedCompanyRecord)
                        setUserMessage({ success: true, errorMessage: "File Uploaded successfully" })
                    } else {
                        setUserMessage({ success: false, errorMessage: response.errorMessage })
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
                setUserMessage({ success: false, errorMessage: error })
            })
    }



    return [{ companyData, isLoading, userMessage, getCompanyData, updateCompany, saveCompany, uploadLogo, updateCompanyReporting }];
}
