import React from 'react'
import { useHistory } from 'react-router-dom'
import { useLocation } from "react-router-dom"
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import EmailValidationList from '../lists/EmailValidationList';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 240,
    },
    title: { marginBottom: theme.spacing(2) }
}))

const EmailValidationPage = () => {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const emailData = location.state


    const returnToSend = () => {
        console.log("return")
        history.push({pathname: "/email/send"})
    }


    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.title}>
                    <Grid item>
                        <Typography variant="h5">{"Vlaidate Emails"}</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={returnToSend}>{"Return"}</Button>
                    </Grid>
                </Grid>
                <EmailValidationList emailData={emailData}/>
            </Paper>
        </Container>

    )
}

export default EmailValidationPage