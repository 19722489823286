import { getPersonAPI, updatePersonAPI, createPersonAPI } from '../requests/person'
import moment from 'moment'
import { uploadFileAPI } from '../requests/file'
import { useState, useContext } from 'react'
import { useAuth } from '../hooks/auth'

export const usePerson = ({ context }) => {
    const [personData, setPersonData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [userMessage, setUserMessage] = useState({})
    const { person, dispatchPerson } = useContext(context)
    const auth = useAuth()

    // Update local person state
    const updatePerson = (data) => {
        const updatedPersonData = { ...personData, ...data }
        setPersonData(updatedPersonData)
    }

    // Save Person Data to context and server
    const savePerson = () => {
        if (personData.id) {
            setIsLoading(true)
            console.log("Calling update:")
            console.log(personData)
            updatePersonAPI(personData)
                .then((response) => {
                    setIsLoading(false)
                    console.log(response)
                    if ("unauthorized" in response) {
                        setUserMessage({ isError: true, text: "Unauthorized" })
                    } else {
                        if (response.success) {
                            dispatchPerson({ type: 'UPDATE_PERSON', data: response.data })
                        } else {
                            setUserMessage({ isError: true, text: response.errorMessage })
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false)
                    console.log(error)
                    setUserMessage({ isError: false, text: error })
                })
        } else {
            setIsLoading(true)
            console.log("Calling create:")
            console.log(personData)
            createPersonAPI(personData)
                .then((response) => {
                    setIsLoading(false)
                    console.log(response)
                    if ("unauthorized" in response) {
                        setUserMessage({ isError: true, text: "Unauthorized" })
                    } else {
                        if (response.success) {
                            // setUserMessage({ isError: false, text: "Data saved successfully" })
                            // setTimeout(() => { setUserMessage({}) }, 1500)
                            dispatchPerson({ type: 'NEW_PERSON', data: response.data })
                        } else {
                            setUserMessage({ isError: true, text: response.errorMessage })
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false)
                    console.log(error)
                    setUserMessage({ isError: false, text: error })
                })

        }

    }


    const getPersonData = ({ id }) => {
        const selectedPerson = person.data.find((person) => (person.id === id))
        let timestamp = undefined
        if (selectedPerson) {
            setPersonData(selectedPerson)
            timestamp = selectedPerson.updatedOn
        }

        // But also go to server for latest data
        setIsLoading(true)
        getPersonAPI({ id, timestamp })
            .then((response) => {
                setIsLoading(false)
                console.log(response)
                if ("unauthorized" in response) {
                    setUserMessage({ isError: true, text: "unauthorized" })
                } else if ("noChange" in response) {
                    return
                } else {
                    if (response.success) {
                        dispatchPerson({ type: 'UPDATE_PERSON', data: response.data })
                    } else {
                        setUserMessage({ isError: true, text: response.errorMessage })
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                setUserMessage({ isError: true, text: error })
            })
    }

    const uploadPhoto = ({ fileName, file }) => {
        setIsLoading(true)
        uploadFileAPI({ fileName, file, path: "images/avatars" })
            .then((response) => {
                setIsLoading(false)
                console.log(response)
                if ("unauthorized" in response) {
                    setUserMessage({ success: false, errorMessage: "Unauthorized" })
                } else {
                    if (response.success) {
                        const updatedUserRecord = { ...personData, photoURL: response.fileName }
                        console.log(updatedUserRecord)
                        setPersonData(updatedUserRecord)
                        setUserMessage({ success: true, errorMessage: "File Uploaded successfully" })
                    } else {
                        setUserMessage({ success: false, errorMessage: response.errorMessage })
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
                setUserMessage({ success: false, errorMessage: error })
            })
    }



    return [{ personData, isLoading, userMessage, getPersonData, updatePerson, savePerson, uploadPhoto, setUserMessage }];

}
