const DOMAIN = "https://77gztusqbd.execute-api.us-east-1.amazonaws.com/"

const getPeriodDataAPI = () => {
    const url = `${DOMAIN}admin/periods`

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);

    console.log(`Call Get Period Data API: ${url}`)
    return fetch(url, {method: 'GET', headers: headers}).then((response) => {
        if (response.status === 401) {
            return {unauthorized:true}
        } 
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    });
};

const updatePeriodDataAPI = (periodData) => {
    const url = `${DOMAIN}admin/periods`

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);

    console.log(`Call Update Period Data API: ${url}`)
    return fetch(url, {method: 'POST', headers: headers, body: JSON.stringify(periodData)})
    .then((response) => {
        if (response.status === 401) {
            return {unauthorized:true}
        } 
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    })
}

export {getPeriodDataAPI, updatePeriodDataAPI}
