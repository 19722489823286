import React from 'react';
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
}));


export default function Reporting({ emailData }) {
    const classes = useStyles()

    return (
        <React.Fragment>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Email Type</TableCell>
                        <TableCell>Sent To</TableCell>
                        <TableCell>CC</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {emailData.map((item, idx) => (
                        <TableRow key={idx} className={classes.tableRow}>
                        <TableCell>{moment(item.timestamp).local().format("MMM Do, YYYY - h:mm a")}</TableCell>
                        <TableCell>{item.emailType}</TableCell>
                        <TableCell>{item.receiver}</TableCell>
                        <TableCell>{item.ccReceiver}</TableCell>
                        <TableCell>{item.eventType}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}