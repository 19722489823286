import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

const PublicRoute = ({ component: Component, ...rest }) => {
    let auth = useAuth();
    const isAuthenticated = !!auth.userAuthenticated;

    return (<Route {...rest} component={(props) => (
        isAuthenticated ? (
            <Redirect to='/dashboard' />
        ) : (
                <Component {...props} />
            )
    )} />
    )
};

export default PublicRoute;