import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { useAuth } from '../../hooks/auth';
import noPhoto from '../../assets/no_photo.png'
import { usePerson } from '../../hooks/person'

const useStyles = makeStyles((theme) => ({
    container: { padding: theme.spacing(2) },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    photoCard: {
        maxWidth: 180,
        maxHeight: 180,
        raised: true
    },
    photo: {
        height: 180,
        width: 180,
        margin: -16,
        objectFit: "cover"
    },
    userPhoto: {
        marginRight: 20,
        marginBottom: 20,
        display: "flex",
        alignItems: "center"
    },
    textField: { paddingRight: "2rem" },
    title: { marginBottom: theme.spacing(2) },
    button: { display: "flex", alignItems: "center" },
    textOK: { color: "green" },
    textError: { color: "red" },
    userMessage: { marginTop: theme.spacing(2) }
}));



const UserProfile = () => {
    const auth = useAuth()
    const userData = auth.getUserData()
    const classes = useStyles()
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [emailHelper, setEmailHelper] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneHelper, setPhoneHelper] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [usState, setUSState] = useState('');
    const [photoURL, setPhotoURL] = useState('');
    const [currentPassword, setCurrentPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [newPassword2, setNewPassword2] = useState();
    const [userMessage, setUserMessage] = useState();

    const user = usePerson()


    // Do once on page load
    useEffect(() => {
        console.log("Initialize Fields")
        if (!userData) {
            return
        }
        const userName = `${userData.givenName} ${userData.familyName}`
        setName(userName)
        if (userData.photoURL) {
            setPhotoURL(userData.photoURL)
        } else {
            setPhotoURL(noPhoto)
        }
        if (userData.email) setEmail(userData.email)
        if (userData.phone) setPhone(userData.phone)
        if (userData.address) setAddress(userData.address)
        if (userData.city) setCity(userData.city)
        if (userData.state) setUSState(userData.state)
        if (userData.zip) setZip(userData.zip)

    }, []);


    const onChange = (e) => {
        let isValid;
        switch (e.target.id) {
            case 'email':
                setEmail(e.target.value)
                isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(e.target.value)
                if (!isValid) {
                    setEmailHelper("Invalid email")
                } else {
                    setEmailHelper('')
                }
                break;
            case 'phone':
                setPhone(e.target.value)
                isValid = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(e.target.value)
                if (!isValid) {
                    setPhoneHelper("Invalid phone number")
                } else {
                    setPhoneHelper('')
                }
                break;
            default:
                break;
        }
    }

    const getUserData = () => {
        console.log({ name, email, phone, address, city, zip, usState, photoURL })
        console.log(user)
        const nameArray = name.split(' ')
        let givenName = ''
        let familyName = ''

        if (nameArray.length === 2) {
            givenName = nameArray[0]
            familyName = nameArray[1]
        } else if (nameArray.length === 1) {
            familyName = nameArray[0]
        } else if (nameArray.length > 2) {
            familyName = nameArray[nameArray.length - 1]
            for (var i = 0; i < nameArray.length - 1; i++) {
                givenName += nameArray[i]
                givenName += ' '
            }
        }
        return { id: userData.userID, familyName, givenName, email, phone, address, city, zip, state: usState, photoURL }
    }

    const updateCallback = ({ success, unauthorized, errorMessage, data }) => {
        console.log("Start Callback on user profile screen")
        // Check for 401 expired Token: Refresh login and go again
        if (unauthorized) {
            auth.login(({ success }) => {
                if (!success) {
                    auth.logout()
                }
            });
            return
        }

        // setSpinner(false)
        if (success) {
            setUserMessage({ isError: false, text: "User record updated successfully" })
            auth.updateUserData(data)
        } else {
            //   setSpinner(false)
            console.log(errorMessage)
            let errorMessageText = errorMessage || "Dummy Message";
            console.log("There was an error: ", errorMessageText);
            if (errorMessageText.includes("NotAuthorizedException")) {
                errorMessageText = "Invalid login credentials - Please try again"
            }
            setUserMessage({ isError: true, text: errorMessageText })
        }
    };

    const saveData = () => {
        user.update(getUserData(), updateCallback)
    }

    const uploadPhotoCallback = ({ success, unauthorized, errorMessage, data }) => {
        // Check for 401 expired Token: Refresh login and go again
        console.log("Upload Photo callback: " + success + " " + data)

        if (unauthorized) {
            auth.login(({ success }) => {
                if (!success) {
                    auth.logout()
                }
            });
            return
        }

        // setSpinner(false)
        if (success) {
            setPhotoURL(data)
        } else {
            //   setSpinner(false)
            console.log(errorMessage)
            let errorMessageText = errorMessage || "Dummy Message";
            console.log("There was an error: ", errorMessageText);
            if (errorMessageText.includes("NotAuthorizedException")) {
                errorMessageText = "Invalid login credentials - Please try again"
            }
            setUserMessage({ isError: true, text: errorMessageText })
        }
    };

    const changePhoto = (e) => {
        // If no file selected - return
        if (e.target.files.length === 0) {
            return
        }

        // Validate file format
        const newUserPhoto = e.target.files[0]
        let fileName = userData.userID
        if (newUserPhoto.type === "image/jpeg") {
            fileName += ".jpg"
        } else if (newUserPhoto.type === "image/png") {
            fileName += ".png"
        } else {
            setUserMessage({ isError: true, text: "Invalid format for photo - please use .jpg or .png" })
            return
        }
        
        // All good - replace existing photo
        var oFReader = new FileReader();
        oFReader.readAsDataURL(newUserPhoto);
        oFReader.onload = function (oFREvent) {
            setPhotoURL(oFREvent.target.result);
        };

        // Now upload to AWS
        user.uploadPhoto({fileName, file: newUserPhoto}, uploadPhotoCallback)

    }


    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.title}>
                    <Grid item>
                        <Typography variant="h5">User Profile</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={saveData}>Save</Button>
                    </Grid>
                </Grid>

                <Grid container direction="row">
                    <Grid item md={2} xs={12}>
                        <Grid container direction="column" className={classes.userPhoto}>
                            <Card className={classes.photoCard}>
                                <CardContent>
                                        <CardMedia htmlFor="photo-upload" component="span">
                                            <div>
                                                <img src={photoURL} className={classes.photo} htmlFor="photo-upload" />
                                            </div>
                                        </CardMedia>

                                </CardContent>
                            </Card>
                            <input
                                accept="image/*"
                                id="photo-upload"
                                hidden
                                type="file"
                                onChange={changePhoto}
                            />
                            <label htmlFor="photo-upload">
                                <Button component="span">Change Photo</Button>
                            </label>
                        </Grid>
                    </Grid>



                    <Grid item md={5} xs={12} className={classes.textField}>
                        <Grid item>
                            <TextField label="name" id="name" value={name} fullWidth
                                onChange={(e) => setName(e.target.value)} />
                        </Grid>
                        <Grid item>
                            <TextField label="email" id="email" value={email} fullWidth
                                error={!!emailHelper}
                                helperText={emailHelper}
                                onChange={onChange} />
                        </Grid>
                        <Grid item>
                            <TextField label="phone" id="phone" value={phone} fullWidth
                                error={phoneHelper.length > 0}
                                helperText={phoneHelper}
                                onChange={onChange} />
                        </Grid>

                    </Grid>
                    <Grid item md={5} xs={12}>
                        <Grid item>
                            <TextField label="address" id="address" value={address} fullWidth
                                onChange={(e) => setAddress(e.target.value)} />
                        </Grid>
                        <Grid item>
                            <TextField label="city" id="city" value={city} fullWidth
                                onChange={(e) => setCity(e.target.value)} />
                        </Grid>
                        <Grid item>
                            <TextField label="zip" id="zip" value={zip} fullWidth
                                onChange={(e) => setZip(e.target.value)} />
                        </Grid>
                        <Grid item>
                            <InputLabel shrink>State</InputLabel>
                            <Select fullWidth onChange={(e) => setUSState(e.target.value)} value={usState}>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </Select>
                        </Grid>

                    </Grid>
                </Grid>

                <div className={classes.title}>
                    <Typography variant="h6">Password</Typography>
                </div>

                <Grid container direction="row">
                    <Grid item md={3} xs={12}>
                        <TextField className={classes.textField} variant="outlined" value={currentPassword}
                            autoComplete="new-password" type="password" label="current password" fullWidth></TextField>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField className={classes.textField} variant="outlined" value={newPassword}
                            type="password" label="new password" fullWidth></TextField>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <TextField className={classes.textField} variant="outlined" value={newPassword2}
                            type="password" label="repeat new password" fullWidth></TextField>
                    </Grid>
                    <Grid item md={1} xs={12} className={classes.button}>
                        <Button variant="contained" fullWidth>Reset</Button>
                    </Grid>
                </Grid>

                {userMessage && <div className={classes.userMessage}>
                    <Typography variant="h5">
                        <span className={userMessage.isError ? classes.textError : classes.textOK}>
                            {userMessage.text}
                        </span>
                    </Typography>
                </div>}

            </Paper>
        </Container>
    );
}

export default UserProfile;