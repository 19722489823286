const DOMAIN = "https://77gztusqbd.execute-api.us-east-1.amazonaws.com/"

const sendEmailAPI = ({companyList, type, period, test}) => {
    const url = `${DOMAIN}email?type=${type}&period=${period}&test=${test}`

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);

    console.log(`Call Send Email API: ${url}`)
    return fetch(url, {method: 'POST', headers: headers, body: JSON.stringify(companyList)})
    .then((response) => {
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    });
};

const sendMemberEmailAPI = ({memberList, type, test}) => {
    const url = `${DOMAIN}email/member?type=${type}&test=${test}`

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);

    console.log(`Call Send Member Email API: ${url}`)
    return fetch(url, {method: 'POST', headers: headers, body: JSON.stringify(memberList)})
    .then((response) => {
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    });
}

const listEmailTemplatesAPI = () => {
    const url = `${DOMAIN}email/template/list`

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);

    console.log(`Call List Email Template API: ${url}`)
    return fetch(url, {method: 'GET', headers: headers}).then((response) => {
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    });
};

const getEmailTemplateAPI = ({templateID}) => {
    const url = `${DOMAIN}email/template?id=${templateID}`

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);

    console.log(`Call Get Email Template API: ${url}`)
    return fetch(url, {method: 'GET', headers: headers}).then((response) => {
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    });
};

export {sendEmailAPI,listEmailTemplatesAPI,getEmailTemplateAPI,sendMemberEmailAPI}
