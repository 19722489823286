
const DOMAIN = "https://77gztusqbd.execute-api.us-east-1.amazonaws.com/"

const validateLinkAPI = (companyID, personID, period) => {
    const url = `${DOMAIN}cta/validate?companyID=${companyID}&personID=${personID}&period=${period}`
    console.log(`Call Validate Link API: ${url}`)
    return fetch(url, {method: 'GET'}).then((response) => {
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    });
};

const validateMemberLinkAPI = ({personID}) => {
    const url = `${DOMAIN}cta/validatemember?personID=${personID}`
    console.log(`Call Validate Member Link API: ${url}`)
    return fetch(url, {method: 'GET'}).then((response) => {
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    });
};

const makeAskAPI = (companyID, personID, period, text) => {
    const url = `${DOMAIN}cta/ask?companyID=${companyID}&personID=${personID}&period=${period}&ask=${text}`
    console.log(`Call Make Ask API: ${url}`)
    return fetch(url, {method: 'POST'}).then((response) => {
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    });
};

const postCommentAPI = (companyID, personID, period, text) => {
    const url = `${DOMAIN}cta/comment?companyID=${companyID}&personID=${personID}&period=${period}&comment=${text}`
    console.log(`Call Post Comment API: ${url}`)
    return fetch(url, {method: 'POST'}).then((response) => {
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    });
};

const postContactAPI = ({companyID, email, title, name}) => {
    const url = `${DOMAIN}cta/contact?companyID=${companyID}&email=${email}&title=${title}&name=${name}`
    console.log(`Call Post Contact API: ${url}`)
    return fetch(url, {method: 'POST'}).then((response) => {
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    });
};

const confirmReportAPI = ({companyID, personID, period}) => {
    const url = `${DOMAIN}cta/confirmation?companyID=${companyID}&personID=${personID}&period=${period}`
    console.log(`Call Confirm Reporting API: ${url}`)
    return fetch(url, {method: 'GET'}).then((response) => {
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    });
};

const uploadReportAPI = ({companyID, personID, period, fileName, file, path}) => {

    console.log(companyID)
    console.log(personID)
    console.log(period)
    console.log(fileName)
    console.log(path)

    const url = `${DOMAIN}cta/upload?companyID=${companyID}&personID=${personID}&period=${period}&fileName=${fileName}&path=${path}`
    console.log(`Call Reporting Upload API: ${url}`)

    const headers = new Headers();
    headers.append('Content-Type', file.type);

    return fetch(url, {method: 'POST', headers, body: file })
    .then((response) => {
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    });
};

const uploadMemberPhotoAPI = ({personID, fileName, file}) => {

    console.log(personID)
    console.log(fileName)

    const url = `${DOMAIN}cta/member/photo?personID=${personID}&fileName=${fileName}`
    console.log(`Call Member Photo Upload API: ${url}`)

    const headers = new Headers();
    headers.append('Content-Type', file.type);

    return fetch(url, {method: 'POST', headers, body: file })
    .then((response) => {
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    })
}

const postMemberUpdateAPI = ({personID, personData}) => {
    const url = `${DOMAIN}cta/member?id=${personID}`
    console.log(`Call Post Member Update API: ${url}`)

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return fetch(url, {method: 'POST', headers, body: JSON.stringify(personData) })
    .then((response) => {
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    })
}


export {confirmReportAPI, validateLinkAPI, postMemberUpdateAPI, validateMemberLinkAPI, makeAskAPI, uploadReportAPI, uploadMemberPhotoAPI, postCommentAPI, postContactAPI}
