import { createMuiTheme } from '@material-ui/core/styles';

const nycBlue = "#0469AC";

export const theme = createMuiTheme({
    palette: {
        common: {
            blue: `${nycBlue}`,
        },
        primary: {
            main: `${nycBlue}`
        },
        background: {
            default: "#FAFAFA"
        }
    },
    typography: {
        h6: {fontWeight: 300},
        button: {
            textTransform: 'none'
          }
    }
});