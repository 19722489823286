import moment from 'moment'

const companyReducer = (state, action) => {
    console.log("REDUCER CALLED: " + action.type)
    console.log(action.type == "UPDATE_COMPANIES")
    switch (action.type) {
        case "POPULATE_COMAPNIES":
            console.log("POPULATE_COMAPNIES")
            return { timestamp: moment(), data: action.data }
        case "UPDATE_COMPANIES":
            console.log("UPDATE_COMAPNIES")
            console.log(state)
            const updatedCompanies = state.data.map((company) => {
                const updatedCompany = state.data.find((item) => item.id === company.id)
                if (updatedCompany) {
                    return {...company, ...updatedCompany}
                } else {
                    return company
                }
            })
            console.log(updatedCompanies)
            return { timestamp: state.timestamp, data: updatedCompanies }
        case "UPDATE_COMPANY":
            console.log("UPDATE_COMPANY " + action.data.id)
            const updatedData = state.data.map((company) => {
                if (company.id === action.data.id) {
                    return {...company, ...action.data}
                } else {
                    return company
                }
            })
            return { timestamp: state.timestamp, data: updatedData }
        case "NEW_COMPANY":
            console.log("NEW_COMPANY: " + action.data.id)
            return { timestamp: state.timestamp, data: [...state.data, action.data]}
        case "TESTING":
            return state
        default:
            return state
    }
}

export default companyReducer