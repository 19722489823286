import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import PageFooter from '../ui/PageFooter'
import { useAdmin } from '../../hooks/useAdmin'

const useStyles = makeStyles((theme) => ({
    container: { padding: theme.spacing(2) },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    title: { marginBottom: theme.spacing(2) }
}));

const ReportingAdminPage = () => {
    const classes = useStyles();
    const [{ isLoading, userMessage, periodData, getPeriods, updatePeriods, savePeriods }] = useAdmin()

    const currentPeriod = () => {
        const currentPeriodRecord = periodData.find((period) => period.isCurrent)
        return currentPeriodRecord ? currentPeriodRecord.period : ''
    }

    const isPeriodOpen = () => {
        const currentPeriodRecord = periodData.find((period) => period.isCurrent)
        return currentPeriodRecord ? currentPeriodRecord.isOpen : false
    }

    useEffect(() => {
        console.log("Getting Periods>>>>>>")
        getPeriods()
    }, []);

    const addNewPeriod = () => {
        // Work out new period
        const latestPeriod = periodData.map((item) => item.period).sort().reverse()[0]
        const latestPeriodArray = latestPeriod.split('-')
        let year = latestPeriodArray[0]
        let quarter = latestPeriodArray[1]

        // Update to next period
        quarter++
        if (quarter > 4) {
            quarter = 1
            year++
        }
        const period = year + "-" + quarter

        const newPeriodData = [
            ...periodData,
            {period, "isOpen":false, "isCurrent":false}
        ]
        newPeriodData.sort((a,b) => (a.period>b.period)? -1 : 1)

        // Update data with new period value
        updatePeriods(newPeriodData)
    }

    const openClosePeriod = () => {
        // Update current period to open/closed
        const newPeriodData = periodData.map((item) => (
            {...item, 
                "isOpen": item.isCurrent ? !item.isOpen : item.isOpen}
        ))
        updatePeriods(newPeriodData)
    }
    
    const updateCurrentPeriod = (newPeriod) => {
        // Update data for new period
        const newPeriodData = periodData.map((item) => (
            {...item, 
                "isOpen": false,
                "isCurrent": item.period === newPeriod}
        ))
        updatePeriods(newPeriodData)
    }

    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <Typography variant="h5" className={classes.title}>Reporting Admin</Typography>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item md={3}>
                        <Typography variant="h6">Current Reporting Period</Typography>
                    </Grid>

                    <Grid item md={3}>
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel >Reporting Period</InputLabel>
                            <Select labelId="type-select-label" value={currentPeriod()} defaultValue={currentPeriod()}
                                    onChange={(e) => { updateCurrentPeriod(e.target.value) }}>
                                {periodData.map((item) => (<MenuItem key={item.period} value={item.period}>
                                    {item.period} {item.isOpen ? "Open" : "Closed"} {item.isCurrent ? "*" : ""}
                                </MenuItem>))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={2}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isPeriodOpen()}
                                    onChange={openClosePeriod}
                                    color="primary"
                                />
                            }
                            label={isPeriodOpen() ? "Open" : "Closed"}
                        />

                    </Grid>

                    <Grid item md={1}>
                    <Button variant="contained" onClick={addNewPeriod}>
                        New
                    </Button>
                </Grid>

                    <Grid item md={1}>
                        <Button color="primary" variant="contained" onClick={savePeriods}>
                            Save
                        </Button>
                    </Grid>
                </Grid>

                <PageFooter userMessage={userMessage} spinner={isLoading} />

            </Paper>
        </Container>
    );
}

export default ReportingAdminPage;