import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useProvideCTA } from '../../hooks/cta'
import logo from '../../assets/nya_logo.png'


const useStyles = makeStyles((theme) => ({
    // container: {width: "100%", minHeight: "100vh"},
    container: {
        padding: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    toolbarMargin: { ...theme.mixins.toolbar },
    input: { display: 'none' },
    section: { paddingTop: theme.spacing(2) },
    textOK: { color: "green" },
    textError: { color: "red" },
    spinner: { display: 'flex', justifyContent: 'center' }


    // page: {marginTop:"10erm", }
}));


const CtaReportUploadPage = () => {
    const classes = useStyles()
    const { companyID } = useParams()
    const [spinner, setSpinner] = useState(true);
    const [userMessage, setUserMessage] = useState();
    const [companyAsk, setcompanyAsk] = useState('');


    const params = new URLSearchParams(useLocation().search);
    const personID = params.get('n')
    const period = params.get('p')
    const cta = useProvideCTA()

    const apiCallback = ({ success, errorMessage }) => {
        setSpinner(false)
        if (errorMessage) {
            setUserMessage({ isError: !success, text: errorMessage })
        }
    };
    const makeAskCallback = ({ success, errorMessage }) => {
        setSpinner(false)
        if (success) {
            setcompanyAsk('')
            setUserMessage({ isError: false, text: "Request successfully made - your contact will get back to you if more information is needed." })
        } else {
            let errorMessageText = "Oops, we're sorry but something went wrong: "
            if (errorMessage) {
                errorMessageText += errorMessage 
            } else {
                errorMessageText +=  "Please contact NYA directly"
            }
            console.log(errorMessageText);
            setUserMessage({ isError: true, text: errorMessageText })        }
    };
    const uploadReportCallback = ({ success, errorMessage }) => {
        setSpinner(false)
        if (success) {
            setUserMessage({ isError: false, text: "Thank you. Your report has successfully been uploaded and will be distributed to your shareholders at NYA." })
        } else {
            let errorMessageText = "Oops, we're sorry but something went wrong: "
            if (errorMessage) {
                errorMessageText += errorMessage 
            } else {
                errorMessageText +=  "Please contact NYA directly"
            }
            console.log(errorMessageText);
            setUserMessage({ isError: true, text: errorMessageText })
        }
    };

    // Do once on page load
    useEffect(() => {
        setSpinner(true)
        cta.validateLink({ companyID, personID, period }, apiCallback)
    }, []);

    const makeAsk = () => {
        setSpinner(true)
        cta.makeAsk({ companyID, personID, period, text: companyAsk }, makeAskCallback)
    }

    const uploadReport = (e) => {
        const allFiles = e.target.files
        Array.from(e.target.files).forEach(file => {
            // Now upload to AWS
            setSpinner(true)
            cta.uploadReport({ companyID, personID, period, fileName:file.name, file}, uploadReportCallback)
        })
    }


    return (
        <div>
            <React.Fragment>
                <AppBar position="fixed" color="primary" className={classes.appBar}>
                    <Toolbar>
                        <img src={logo} alt="NYA Logo" className={classes.logo} />
                    </Toolbar>
                </AppBar>
            </React.Fragment>

            <div className={classes.toolbarMargin} />

            <Container className={classes.container}>
                <Paper className={classes.paper}>
                    <Typography variant="h4">Report Upload</Typography>
                    <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.section}>
                        <Grid item>
                            <Typography variant="body1">Please upload your report here and we will distribute to our members who are your shareholders.</Typography>
                            <Typography variant="body1">(You may upload multiples files)</Typography>
                        </Grid>
                        <Grid item>
                            <input
                                className={classes.input}
                                id="report-file"
                                multiple
                                type="file"
                                onChange={uploadReport}
                            />
                            <label htmlFor="report-file">
                                <Button variant="contained" color="primary" component="span">
                                    Upload Report
                        </Button>
                            </label>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.section}>
                        <Typography variant="body1">Additionally if you have a request from our members (e.g. finding talent, introductions, advice), you may submit that here:</Typography>
                    </Grid>
                    <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.section}>
                        <Grid item md={10}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="ask"
                                label="Request"
                                name="ask"
                                value={companyAsk}
                                onChange={(e) => {setcompanyAsk(e.target.value) }}
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" component="span" onClick={makeAsk}>
                                Make Request
                            </Button>
                        </Grid>
                    </Grid>




                    {userMessage && <div className={classes.section}>
                        <Typography variant="h5">
                            <span className={userMessage.isError ? classes.textError : classes.textOK}>
                                {userMessage.text}
                            </span>
                        </Typography>
                    </div>}

                    {spinner && (
                        <Grid container direction="row" justify="center" alignItems="center" className={classes.section}>
                            <CircularProgress />
                        </Grid>
                    )}


                </Paper>
            </Container>
        </div>

    );
}

export default CtaReportUploadPage;