import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useProvideCTA } from '../../hooks/cta'
import logo from '../../assets/nya_logo.png'


const useStyles = makeStyles((theme) => ({
    // container: {width: "100%", minHeight: "100vh"},
    container: {
        padding: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    toolbarMargin: { ...theme.mixins.toolbar },
    input: { display: 'none' },
    section: { paddingTop: theme.spacing(2) },
    textOK: { color: "green" },
    textError: { color: "red" },
    spinner: { display: 'flex', justifyContent: 'center' }


    // page: {marginTop:"10erm", }
}));


const CtaCommentPage = () => {
    const classes = useStyles()
    const { companyID } = useParams()
    const [spinner, setSpinner] = useState(true);
    const [companyName, setCompanyName] = useState('')
    const [userMessage, setUserMessage] = useState();
    const [comment, setComment] = useState('');


    const params = new URLSearchParams(useLocation().search);
    const personID = params.get('n')
    const period = params.get('p')
    const cta = useProvideCTA()

    const validateCallback = ({ success, data = {}, errorMessage }) => {
        console.log(data)
        setSpinner(false)
        setCompanyName(data.name ? data.name : '')
        if (!success) {
            let errorMessageText = "Oops, we're sorry but something went wrong: "
            if (errorMessage) {
                errorMessageText += errorMessage 
            } else {
                errorMessageText +=  "Please contact NYA directly"
            }
            console.log(errorMessageText);
            setUserMessage({ isError: true, text: errorMessageText })        }
    };

    const commentCallback = ({ success, errorMessage }) => {
        setSpinner(false)
        if (success) {
            setComment('')
            setUserMessage({ isError: false, text: "Thank you. Our records have been updated with your comment." })
        } else {
            let errorMessageText = errorMessage || "Dummy Message";
            console.log("There was an error: ", errorMessageText);
            setUserMessage({ isError: true, text: errorMessageText })
        }
    };


    // Do once on page load
    useEffect(() => {
        setSpinner(true)
        cta.validateLink({ companyID, personID, period }, validateCallback)
    }, []);

    const postComment = () => {
        setSpinner(true)
        cta.postComment({ companyID, personID, period, text: comment }, commentCallback)
    }


    return (
        <div>
            <React.Fragment>
                <AppBar position="fixed" color="primary" className={classes.appBar}>
                    <Toolbar>
                        <img src={logo} alt="NYA Logo" className={classes.logo} />
                    </Toolbar>
                </AppBar>
            </React.Fragment>

            <div className={classes.toolbarMargin} />

            <Container className={classes.container}>
                <Paper className={classes.paper}>
                    <Typography variant="h4">NYA Quarterly Report: {companyName}</Typography>
                    <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.section}>
                        <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.section}>
                            <Typography variant="body1">Please let us know if there are mitigating factors that will delay this period's report, or when we can expect to receive it:</Typography>
                        </Grid>
                        <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.section}>
                            <Grid item md={10}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="comment"
                                    label="Comment"
                                    name="comment"
                                    value={comment}
                                    onChange={(e) => { setComment(e.target.value) }}
                                />
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" component="span" onClick={postComment}>
                                    Make Comment
                            </Button>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid container>
                        {userMessage && <div className={classes.section}>
                            <Typography variant="h5">
                                <span className={userMessage.isError ? classes.textError : classes.textOK}>
                                    {userMessage.text}
                                </span>
                            </Typography>
                        </div>}

                        {spinner && (
                            <Grid container direction="row" justify="center" alignItems="center" className={classes.section}>
                                <CircularProgress />
                            </Grid>
                        )}

                    </Grid>
                </Paper>
            </Container>
        </div>

    );
}

export default CtaCommentPage;