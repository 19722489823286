import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useProvideCTA } from '../../hooks/cta'
import logo from '../../assets/nya_logo.png'


const useStyles = makeStyles((theme) => ({
    // container: {width: "100%", minHeight: "100vh"},
    container: {
        padding: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    toolbarMargin: { ...theme.mixins.toolbar },
    input: { display: 'none' },
    section: { paddingTop: theme.spacing(2) },
    textOK: { color: "green" },
    textError: { color: "red" },
    spinner: { display: 'flex', justifyContent: 'center' }


    // page: {marginTop:"10erm", }
}));


const CtaContactDetailsPage = () => {
    const classes = useStyles()
    const { companyID } = useParams()
    const [spinner, setSpinner] = useState(true);
    const [companyName, setCompanyName] = useState('')
    const [userMessage, setUserMessage] = useState();
    const [name, setName] = useState('');
    const [emailHelper, setEmailHelper] = useState('');
    const [email, setEmail] = useState('');
    const [title, setTitle] = useState('');


    const params = new URLSearchParams(useLocation().search);
    const personID = params.get('n')
    const period = params.get('p')
    const cta = useProvideCTA()

    const validateCallback = ({ success, data = {}, errorMessage }) => {
        console.log(data)
        setSpinner(false)
        setCompanyName(data.name ? data.name : '')
        if (!success) {
            let errorMessageText = errorMessage || "Dummy Message";
            console.log("There was an error: ", errorMessageText);
            setUserMessage({ isError: true, text: errorMessageText })
        }
    };

    const contactCallback = ({ success, errorMessage }) => {
        setSpinner(false)
        if (success) {
            setName('')
            setTitle('')
            setEmail('')
            setUserMessage({ isError: false, text: "Thank you for your help. Our records have been updated." })
        } else {
            let errorMessageText = errorMessage || "Dummy Message";
            console.log("There was an error: ", errorMessageText);
            setUserMessage({ isError: true, text: errorMessageText })
        }
    };


    // Do once on page load
    useEffect(() => {
        setSpinner(true)
        cta.validateLink({ companyID, personID, period }, validateCallback)
    }, []);

    const submitData = () => {
        setSpinner(true)
        console.log(name)
        console.log(email)
        console.log(title)
        cta.postContact({ name, email, title, companyID }, contactCallback)
    }

    const emailUpdate = (emailText) => {
        setEmail(emailText)
        if (emailText.length == 0) {
            setEmailHelper('')
            return
        }
        const isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(emailText)
        console.log(isValid)
        if (!isValid) {
            setEmailHelper("Invalid email")
        } else {
            setEmailHelper('')
        }
    }


    return (
        <div>
            <React.Fragment>
                <AppBar position="fixed" color="primary" className={classes.appBar}>
                    <Toolbar>
                        <img src={logo} alt="NYA Logo" className={classes.logo} />
                    </Toolbar>
                </AppBar>
            </React.Fragment>

            <div className={classes.toolbarMargin} />

            <Container className={classes.container}>
                <Paper className={classes.paper}>
                    <Typography variant="h4">NYA Portfolio Reporting: {companyName}</Typography>
                    <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.section}>
                        <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.section}>
                            <Typography variant="body1">Please enter the name, title (e.g. CEO) and email of the preferred contact at {companyName}:</Typography>
                        </Grid>
                        <Grid container direction="column" className={classes.section}>
                            <Grid item lg={6} xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    name="name"
                                    value={name}
                                    onChange={(e) => { setName(e.target.value) }}
                                />
                            </Grid>
                            <Grid item lg={6} xs={12} className={classes.section}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="title"
                                    label="Title"
                                    name="title"
                                    value={title}
                                    onChange={(e) => { setTitle(e.target.value) }}
                                />
                            </Grid>
                            <Grid item lg={6} xs={12} className={classes.section}>
                                <TextField label="email" id="email" value={email} fullWidth
                                    variant="outlined"
                                    error={!!emailHelper}
                                    helperText={emailHelper}
                                    onChange={(e) => { emailUpdate(e.target.value) }} />
                            </Grid>
                            <Grid item className={classes.section}>
                                <Button variant="contained" color="primary" component="span" 
                                        disabled={!!emailHelper || name.length == 0 || email.length == 0} onClick={submitData}>
                                    Submit
                            </Button>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid container>
                        {userMessage && <div className={classes.section}>
                            <Typography variant="h5">
                                <span className={userMessage.isError ? classes.textError : classes.textOK}>
                                    {userMessage.text}
                                </span>
                            </Typography>
                        </div>}

                        {spinner && (
                            <Grid container direction="row" justify="center" alignItems="center" className={classes.section}>
                                <CircularProgress />
                            </Grid>
                        )}

                    </Grid>
                </Paper>
            </Container>
        </div>

    );
}

export default CtaContactDetailsPage;