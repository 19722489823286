import { useState, useContext } from 'react'
import { sendEmailAPI, listEmailTemplatesAPI, getEmailTemplateAPI, sendMemberEmailAPI } from '../requests/email';
import { useAuth } from '../hooks/auth';

// Hook for all company Access
export const useEmail = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [userMessage, setUserMessage] = useState()
    const [testData, setTestData] = useState()

    const sendEmail = ({ companyList, type, period, test }) => {
        setIsLoading(true)
        sendEmailAPI({ companyList, type, period, test })
            .then((response) => {
                setIsLoading(false)
                console.log(response)
                if ("unauthorized" in response) {
                    setUserMessage({ isError: true, text: "Unauthorized" })
                } else {
                    if (response.success) {
                        if (response.test) {
                            setTestData(response.data)
                        } else {
                            setUserMessage({ isError: false, text: "Email(s) sent successfully" })
                            setTimeout(() => { setUserMessage({}) }, 1500)
                        }
                    } else {
                        setUserMessage({ isError: true, text: response.data.toString() })
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
                setUserMessage({ isError: false, text: error })
            })
    };

    const sendMemberEmail = ({ memberList, type, test }) => {
        setIsLoading(true)
        sendMemberEmailAPI({ memberList, type, test })
            .then((response) => {
                setIsLoading(false)
                console.log(response)
                if ("unauthorized" in response) {
                    setUserMessage({ isError: true, text: "Unauthorized" })
                } else {
                    if (response.success) {
                        if (response.test) {
                            setTestData(response.data)
                        } else {
                            setUserMessage({ isError: false, text: "Email(s) sent successfully" })
                            setTimeout(() => { setUserMessage({}) }, 1500)
                        }
                    } else {
                        setUserMessage({ isError: true, text: response.data.toString() })
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
                setUserMessage({ isError: false, text: error })
            })
    };



    return [{ isLoading, userMessage, testData, sendEmail, sendMemberEmail }]
}
