import { responsiveFontSizes } from "@material-ui/core";

const DOMAIN = "https://77gztusqbd.execute-api.us-east-1.amazonaws.com/"

const loginAPI = ({userName, password, refreshToken}) => {
    console.log("Call Login API - refresh token: " + !!refreshToken)

    const url = refreshToken ? `${DOMAIN}auth/login?refreshToken=${refreshToken}` :
                               `${DOMAIN}auth/login?userName=${userName}&password=${password}`
    return fetch(url).then((response) => {
        if (response.status !== 200) {
            const errorText = 'Looks like there was a problem. Status Code: ' + response.status;
            console.log(errorText);
            return Promise.reject(errorText);
        }
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    })
}

const changePasswordAPI = ({userName, password, session}) => {
    console.log("Call Change Password API - response to Auth Challenge")

    const url = `${DOMAIN}auth/password/change?userName=${userName}&newPassword=${password}&session=${session}`
    return fetch(url).then((response) => {
        if (response.status !== 200) {
            const errorText = 'Looks like there was a problem. Status Code: ' + response.status;
            console.log(errorText);
            return Promise.reject(errorText);
        }
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    })
}


const resetPasswordAPI = ({userName}) => {

}


export { loginAPI, changePasswordAPI}


