import { Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery"
import SwimHeader from '../components/ui/SwimHeader';
import { theme } from '../components/ui/theme';
import React from 'react';

const useStyles = makeStyles(() => {

    return ({
        content: { marginLeft: 200, flexGrow: 1, height: '100vh', overflow: 'auto' },
        contentPhone: { marginLeft: 120, flexGrow: 1, height: '100vh', overflow: 'auto' }
    })
});


const SwimRoute = ({ component: Component, ...rest }) => {
    const classes = useStyles()
    const isPhone = useMediaQuery(theme.breakpoints.down('xs'))

    return (<Route {...rest} component={(props) => (
        <React.Fragment>
            <SwimHeader />
            <Component {...props} />
        </React.Fragment>
    )} />
    );
}


export default SwimRoute;