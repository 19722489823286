const experience = [
    { title: "Advertising"},
    { title: "Agriculture"},
    { title: "Construction"},
    { title: "Consumer Products"},
    { title: "Consumer Services"},
    { title: "Education"},
    { title: "Electronics/High Tech"},
    { title: "Energy"},
    { title: "Fashion"},
    { title: "Financial Services"},
    { title: "Food & Beverage"},
    { title: "Gaming"},
    { title: "Healthcare"},
    { title: "Legal"},
    { title: "Life Sciences"},
    { title: "Logistics"},
    { title: "Manufacturing"},
    { title: "Media/Entertainment"},
    { title: "Professional Services"},
    { title: "Real Estate"},
    { title: "Retail Tech"},
    { title: "Software/IT"},
    { title: "Sports"},
    { title: "Transportation"},
    { title: "Travel & Leisure"}]
    
const interests1 = [
    { title: "Advertising"},
    { title: "Agriculture"},
    { title: "Construction"},
    { title: "Consumer Products"},
    { title: "Consumer Services"},
    { title: "Education"},
    { title: "Electronics/High Tech"},
    { title: "Energy"},
    { title: "Fashion"},
    { title: "Financial Services"},
    { title: "Food & Beverage"},
    { title: "Gaming"},
    { title: "Healthcare"},
    { title: "Legal"},
    { title: "Life Sciences"},
    { title: "Logistics"},
    { title: "Manufacturing"},
    { title: "Media/Entertainment"},
    { title: "Professional Services"},
    { title: "Real Estate"},
    { title: "Retail Tech"},
    { title: "Software/IT"},
    { title: "Sports"},
    { title: "Transportation"},
    { title: "Travel & Leisure"}]

const interests2 = [
    { title: "AI"},
    { title: "B2B"},
    { title: "B2B SaaS"},
    { title: "Betting/Gaming"},
    { title: "Blockchain"},
    { title: "Data/Cyber-Security"},
    { title: "DeFi/Crypto"},
    { title: "Digital Media"},
    { title: "DTC Products"},
    { title: "DTC Services"},
    { title: "E-commerce"},
    { title: "Frontier Tech"},
    { title: "Hardware/IOT"},
    { title: "HR Tech"},
    { title: "Marketing Tech"},
    { title: "Marketplaces"},
    { title: "Med Tech"},
    { title: "Sports Tech"},
]

export {experience, interests1, interests2}