import { useState } from 'react'
import { getPeriodDataAPI, updatePeriodDataAPI } from '../requests/admin';
import { useAuth } from '../hooks/auth';

// Hook for all company Access
export const useAdmin = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [userMessage, setUserMessage] = useState()
    const [periodData, setPeriodData] = useState([])
    const auth = useAuth()

    const updatePeriods = (newPeriodData) => {
        setPeriodData(newPeriodData)
    }

    const getPeriods = () => {
        setIsLoading(true)
        getPeriodDataAPI()
            .then((response) => {
                setIsLoading(false)
                console.log(response)
                if ("unauthorized" in response) {
                    setUserMessage({ isError: true, text: "Unauthorized" })
                } else {
                    if (response.success) {
                        // Make into array
                        let periodArray = []
                        for (const [key, value] of Object.entries(response.data)) {
                            periodArray.push({"period":key, "isOpen":value.isOpen, "isCurrent":value.isCurrent})
                        }

                        setPeriodData(periodArray)
                        setUserMessage({ isError: false, text: "Period Data Retrieved OK" })
                        setTimeout(() => { setUserMessage({}) }, 1500)
                    } else {
                        setUserMessage({ isError: true, text: response.errorMessage })
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
                setUserMessage({ isError: false, text: error })
            })
    };

    const savePeriods = () => {
        // Make sure user record has been updated
        const currentPeriodRecord = periodData.find((item) => item.isCurrent)
        console.log(currentPeriodRecord)

        // Reformat to DB object
        let periodsObject = {}
        periodData.forEach((item) => {
            const isOpen = item.isOpen
            const isCurrent = item.isCurrent
            periodsObject[item.period] = {isOpen, isCurrent}
        })
        
        // {item.period, "isClosed":item.isClosed, "isCurrent":item.isCurrent})
        setIsLoading(true)
        updatePeriodDataAPI(periodsObject)
            .then((response) => {
                setIsLoading(false)
                console.log(response)
                if ("unauthorized" in response) {
                    setUserMessage({ isError: true, text: "Unauthorized" })
                } else {
                    if (response.success) {
                        auth.updateUserData({currentPeriod:currentPeriodRecord.period, periodOpen:currentPeriodRecord.isOpen})
                        setUserMessage({ isError: false, text: "Period Data Saved" })
                        setTimeout(() => { setUserMessage({}) }, 1500)
                    } else {
                        setUserMessage({ isError: true, text: response.errorMessage })
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
                setUserMessage({ isError: false, text: error })
            })
    }

    return [{ isLoading, userMessage, periodData, getPeriods, updatePeriods, savePeriods }]
}
