import { sendEmailAPI, listEmailTemplatesAPI, getEmailTemplateAPI } from '../requests/email';

export const useEmail = () => {

    const sendEmail = ({ companyID, type, period }, callBack) => {
        sendEmailAPI(companyID, type, period)
            .then((response) => {
                console.log(response)
                if (response.success) {
                    callBack({ success: true });
                } else {
                    callBack({ success: false, errorMessage: response.errorMessage })
                }
            })
            .catch((error) => {
                console.log(error)
                callBack({ success: false, errorMessage: error })
            })
    };

    const getEmailTemplate = ({templateID}, callBack) => {
        getEmailTemplateAPI({templateID})
            .then((response) => {
                console.log(response)
                if (response.success) {
                    callBack({ success: true, data: response.data });
                } else {
                    callBack({ success: false, errorMessage: response.errorMessage })
                }
            })
            .catch((error) => {
                console.log(error)
                callBack({ success: false, errorMessage: error })
            })
    };

    const listTemplates = (callBack) => {
        listEmailTemplatesAPI()
            .then((response) => {
                console.log(response)
                if (response.success) {
                    callBack({ success: true, data: response.data });
                } else {
                    callBack({ success: false, errorMessage: response.errorMessage })
                }
            })
            .catch((error) => {
                console.log(error)
                callBack({ success: false, errorMessage: error })
            })
    };

    return { sendEmail, listTemplates, getEmailTemplate };
}
