import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import NotesIcon from '@material-ui/icons/Notes'
import EmailIcon from '@material-ui/icons/Email'
import CommentsPopUp from '../ui/CommentsPopUp'
import EmailHistoryPopUp from '../ui/EmailHistoryPopUp'


const useStyles = makeStyles((theme) => ({
    textOK: { color: "green" },
    textError: { color: "red" }
}));

const CompanyReportStatusList = ({companyList = []}) => {
    const classes = useStyles()
    const history = useHistory();
    const [showCommentsPopUp, setCommentsPopUp] = useState(false);
    const [commentsData, setCommentsData] = useState();
    const [showEmailHistoryPopUp, setShowEmailHistoryPopUp] = useState(false);
    const [emailHistory, setEmailHistory] = useState();

    const hideComments = () => {
        setCommentsPopUp(false)
    }
    const showComments = (e, commentsData) => {
        e.stopPropagation()
        setCommentsData(commentsData)
        setCommentsPopUp(true)
    }
    const hideEmailHistory = () => {
        setShowEmailHistoryPopUp(false)
    }
    const showEmailHistory = (e, emailData) => {
        e.stopPropagation()
        setEmailHistory(emailData)
        setShowEmailHistoryPopUp(true)
    }


    const selectRow = (id) => {
        history.push(`/company/${id}`)
    }

    return (
        <React.Fragment>
            <Table size="medium">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Contact</TableCell>
                        <TableCell>NYA Contact</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Comments</TableCell>
                        <TableCell>Email</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {companyList.map((item) => (
                        <TableRow hover key={item.id} onClick={() => {selectRow(item.id)}} >
                            <TableCell>
                                <Avatar src={item.logo} alt={item.name} variant="rounded"/>
                            </TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.contact}</TableCell>
                            <TableCell>{item.nyaContact}</TableCell>
                            <TableCell>
                                <span className={item.reportCompleted ? classes.textOK : classes.textError}>
                                    {item.reportCompleted ? "Completed" : "Missing"}
                                </span>
                            </TableCell>
                            <TableCell>
                            {item.comments ? (
                                <IconButton color="inherit" size='small' onClick={(e) => showComments(e, item.comments)}>
                                    <NotesIcon />
                                </IconButton>
                            ) : "No"}
                            </TableCell>
                            <TableCell>
                                {(item.email && item.email.length > 0) ? (
                                    <IconButton color="inherit" size='small'  onClick={(e) => showEmailHistory(e, item.email)}>
                                        <EmailIcon />
                                    </IconButton>
                                ) : "No"}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <CommentsPopUp open={showCommentsPopUp} handleClose={hideComments} commentsData={commentsData}/>
            <EmailHistoryPopUp open={showEmailHistoryPopUp} handleClose={hideEmailHistory} emailData={emailHistory}/>
        </React.Fragment>
    );
}

export default CompanyReportStatusList