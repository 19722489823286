import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import { useCompany } from '../../hooks/useCompany'
import CompanyContext from '../../context/CompanyContext'
import CompanyBasicData from './CompanyBasicData'
import CompanyContacts from './CompanyContacts'
import CompanyReporting from './CompanyReporting'
import PageFooter from '../ui/PageFooter'
import CompanyIntegration from './CompanyIntegration'

const useStyles = makeStyles((theme) => ({
    container: { padding: theme.spacing(2) },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    title: { marginBottom: theme.spacing(2) },
    subTitle: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
    button: { display: "flex", alignItems: "center" },
    textOK: { color: "green" },
    textError: { color: "red" },
    userMessage: { marginTop: theme.spacing(2) },

}));


const CompanyPage = () => {
    const classes = useStyles();
    const { id } = useParams()
    const history = useHistory()
    const [{companyData, isLoading, userMessage, getCompanyData, updateCompany, saveCompany, uploadLogo, updateCompanyReporting }] = useCompany({context:CompanyContext})
    
    // Do once on page load -> Get Company Data
    useEffect(() => {
        console.log("Call Get Company API")
        if (id != "new") {
            getCompanyData(id)
        }
    }, []);

    // Save Data
    const saveData = () => {
        saveCompany()
        if (id === "new") {
            history.push(`/company/list`)
        }
    }

    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.title}>
                    <Grid item>
                        <Typography variant="h5">{companyData.name ? companyData.name : "New Company"}</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={saveData}>{companyData.id ? "Save" : "Create" }</Button>
                    </Grid>
                </Grid>

                <CompanyBasicData companyData={companyData} updateCompany={updateCompany} uploadLogo={uploadLogo}/>

                <CompanyContacts companyData={companyData} updateCompany={updateCompany}/>

                <CompanyIntegration companyData={companyData} updateCompany={updateCompany}/>

                <CompanyReporting reportingData={companyData.reporting} updateReporting={updateCompanyReporting}/>

                <PageFooter userMessage={userMessage} spinner={isLoading} />

            </Paper>
        </Container>
    );
}

export default CompanyPage;