import React, { useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import PersonBasicData from './PersonBasicData'
import PersonInvestmentInterests from './PersonInvestmentInterests'
import PersonOrgData from './PersonOrgData'
import PersonProfData from './PersonProfData'
import { usePerson } from '../../hooks/usePerson'
import PersonContext from '../../context/PersonContext'
import PageFooter from '../ui/PageFooter'

const useStyles = makeStyles((theme) => ({
    container: { padding: theme.spacing(2) },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    title: { marginBottom: theme.spacing(2) },
    bottom: { marginBottom: theme.spacing(2) },
    subitle: { marginTop: theme.spacing(2) },
    textOK: { color: "green" },
    textError: { color: "red" },
    userMessage: { marginTop: theme.spacing(2) }
}));


const PersonPage = () => {
    const classes = useStyles()
    const { id } = useParams()
    const history = useHistory()
    const [{ personData, isLoading, userMessage, getPersonData, updatePerson, savePerson, uploadPhoto, setUserMessage }] = usePerson({ context: PersonContext })

    // Do once on page load -> Get Person Data
    useEffect(() => {
        if (id != "new") {
            console.log("Call Get Person API")
            getPersonData({ id })
        }
    }, []);

    // Save Data
    const saveData = () => {
        // Check for email and name
        if (!personData.email || !personData.personName) {
            setUserMessage({ isError: true, text: "Plese enter a name and a valid email address" })
            return
        }
        savePerson()
        if (id === "new") {
            history.push(`/person/list`)
        }
    }

    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.title}>
                    <Grid item>
                        <Typography variant="h5">{personData.personName ? personData.personName : "New Person"}</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={saveData} disabled={!personData.personName || !personData.email}>
                            {id === "new" ? "Create" : "Save"}
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={12} xs={12}>
                        <PersonBasicData personData={personData} updatePerson={updatePerson} uploadPhoto={uploadPhoto} />
                    </Grid>
                </Grid>
                <Grid container className={classes.bottom}>
                    <Typography variant="h6">Organization Data</Typography>
                    <Grid item md={12} xs={12}>
                        <PersonOrgData personData={personData} updatePerson={updatePerson} />
                    </Grid>
                </Grid>
                <Grid container className={classes.bottom}>
                    <Typography variant="h6">Professional Data</Typography>
                    <Grid item md={12} xs={12}>
                        <PersonProfData personData={personData} updatePerson={updatePerson} />
                    </Grid>
                </Grid>
                {personData.isAngel && (
                    <Grid container className={classes.bottom}>
                        <Typography variant="h6">Investment Interests</Typography>
                        <Grid item md={12} xs={12}>
                            <PersonInvestmentInterests personData={personData} updatePerson={updatePerson} />
                        </Grid>
                    </Grid>

                )}
                <PageFooter userMessage={userMessage} spinner={isLoading} />
            </Paper>
        </Container>
    );
}

export default PersonPage;