import {useState} from 'react'
import { getDashboardDataAPI } from '../requests/dashboard'

// Hook for dashboard data
export const useDashboard = () => {
    const [dashboardData, setDashboardData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [userMessage, setUserMessage] = useState()

    // Return dashboard data 
    const getDashboardData = () => {
        // Get data from server
        setIsLoading(true)
        getDashboardDataAPI()
            .then((response) => {
                console.log(response)
                setIsLoading(false)
                if ("unauthorized" in response) {
                    console.log("Unauthorized response detected")
                    setUserMessage({ isError: true, text: "unauthorized" })
                } else {
                    if (response.success) {
                        setDashboardData(response.data)
                    } else {
                        setUserMessage({ isError: true, text: response.errorMessage })
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                setIsLoading(false)
                setUserMessage({ isError: true, text: error })
            })
    }

    return [{dashboardData, isLoading, userMessage , getDashboardData }]
}
