import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography'
import noLogo from '../../assets/noLogo.png'


const useStyles = makeStyles((theme) => ({
    photoCard: {
        maxWidth: 180,
        maxHeight: 180,
        raised: true
    },
    photo: {
        height: 180,
        width: 180,
        margin: -16,
        objectFit: "cover"
    },
    userPhoto: {
        marginRight: 20,
        marginBottom: 20,
        display: "flex",
        alignItems: "center"
    },
    textField: { paddingRight: "2rem" },
    sectionTitle: { marginTop: theme.spacing(2) },
    textOK: { color: "green" },
    textError: { color: "red" },
}));

export default ({ companyData = {}, updateCompany, uploadLogo }) => {
    const classes = useStyles()
    const { id = "" } = companyData
    const { logo = "" } = companyData
    const { name = "" } = companyData
    const { sector = "" } = companyData
    const { summary = "" } = companyData
    const { address = "" } = companyData
    const { city = "" } = companyData
    const { zip = "" } = companyData
    const { state = "" } = companyData
    const { country = "" } = companyData
    const [userMessage1, setUserMessage1] = useState();


    const changeLogo = (e) => {
        // If no file selected - return
        if (e.target.files.length === 0) {
            return
        }

        if (!id && !name) {
            setUserMessage1({ isError: true, text: "Please enter a company name before uploading logo" })
            return
        }

        // Validate file format
        const newLogo = e.target.files[0]
        let fileName = companyData.id ? companyData.id : companyData.name.replaceAll(" ", "_")
        if (newLogo.type === "image/jpeg") {
            fileName += ".jpg"
        } else if (newLogo.type === "image/png") {
            fileName += ".png"
        } else {
            setUserMessage1({ isError: true, text: "Invalid format for logo - please use .jpg or .png" })
            return
        }

        // All good - replace existing logo
        var oFReader = new FileReader();
        oFReader.readAsDataURL(newLogo);
        oFReader.onload = function (oFREvent) {
            updateCompany({ "logo": oFREvent.target.result })
        };

        // Now upload to AWS
        uploadLogo({ fileName, file: newLogo })

    }

    return (
        <React.Fragment>
            <Grid container direction="row">
                <Grid item md={2} xs={12}>
                    <Grid container direction="column" className={classes.userPhoto}>
                        <Card className={classes.photoCard}>
                            <CardContent>
                                <CardMedia htmlFor="logo-upload" component="span">
                                    <div>
                                        <img src={logo ? logo : noLogo} className={classes.photo} htmlFor="logo-upload" />
                                    </div>
                                </CardMedia>
                            </CardContent>
                        </Card>
                        <input
                            accept="image/*"
                            id="logo-upload"
                            hidden
                            type="file"
                            onChange={changeLogo}
                        />
                        <label htmlFor="logo-upload">
                            <Button component="span">Change Logo</Button>
                        </label>
                    </Grid>
                </Grid>

                <Grid item md={5} xs={12} className={classes.textField}>
                    <Grid item>
                        <TextField label="name" id="name" value={name} fullWidth
                            onChange={(e) => { updateCompany({ "name": e.target.value }) }} />
                    </Grid>
                    <Grid item>
                        <TextField label="sector" id="sector" value={sector} fullWidth multiline rowsMax="9"
                            onChange={(e) => { updateCompany({ "sector": e.target.value }) }} />
                    </Grid>
                    <Grid item>
                        <TextField label="summary" id="summary" value={summary} fullWidth multiline rowsMax="8"
                            onChange={(e) => { updateCompany({ "summary": e.target.value }) }} />
                    </Grid>

                </Grid>
                <Grid item md={5} xs={12}>
                    <Grid item>
                        <TextField label="address" id="address" value={address} fullWidth
                            onChange={(e) => { updateCompany({ "address": e.target.value }) }} />
                    </Grid>
                    <Grid item>
                        <TextField label="city" id="city" value={city} fullWidth
                            onChange={(e) => { updateCompany({ "city": e.target.value }) }} />
                    </Grid>
                    <Grid item>
                        <TextField label="zip" id="zip" value={zip} fullWidth
                            onChange={(e) => { updateCompany({ "zip": e.target.value }) }} />
                    </Grid>
                    <Grid item>
                        <InputLabel shrink>state</InputLabel>
                        <Select fullWidth onChange={(e) => { updateCompany({ "state": e.target.value }) }}
                            defaultValue={state} value={state}>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </Select>
                    </Grid>
                    <Grid item>
                        <TextField label="country" id="country" value={country} fullWidth
                            onChange={(e) => { updateCompany({ "country": e.target.value }) }} />
                    </Grid>

                </Grid>
            </Grid>
            {userMessage1 && <div className={classes.sectionTitle}>
                <Typography variant="h5">
                    <span className={userMessage1.isError ? classes.textError : classes.textOK}>
                        {userMessage1.text}
                    </span>
                </Typography>
            </div>}
        </React.Fragment>

    )
}