import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import NotesIcon from '@material-ui/icons/Notes'
import EmailIcon from '@material-ui/icons/Email'
import CommentsPopUp from '../ui/CommentsPopUp'
import EmailHistoryPopUp from '../ui/EmailHistoryPopUp'


const headerCells = [
    { id: 'name', label: 'Company Name' },
    { id: 'contact', label: 'Contact' },
    { id: 'nyaContact', label: 'NYA Contact' },
    { id: 'reportCompleted', label: 'Status' },
    { id: 'comments', label: 'Comments' },
    { id: 'history', label: 'Email History' },
]

const useStyles = makeStyles((theme) => ({
    tableRow: { textDecoration: 'none' },
    textOK: { color: "green" },
    textError: { color: "red" },
    visuallyHidden: { border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden',
    padding: 0, position: 'absolute', top: 20, width: 1,}
}));


const TableHeader = (props) => {
    const { classes, order, orderBy, onRequestSort, noSelect, numSelected, rowCount, selectCompany } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(property);
    }

    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    <Checkbox color="primary"
                        disabled={noSelect}
                        onChange={() => { selectCompany() }}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}/>
                </TableCell>
                {headerCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}




const CompanyEmailTable = ({ companyEmailData = [], selectCompany, selections, noSelect, sortData }) => {
    const classes = useStyles()
    const [showCommentsPopUp, setCommentsPopUp] = useState(false);
    const [commentsData, setCommentsData] = useState();
    const [showEmailHistoryPopUp, setShowEmailHistoryPopUp] = useState(false);
    const [emailHistory, setEmailHistory] = useState();
    const [isAsc, setIsAsc] = useState(false)
    const [orderBy, setOrderBy] = useState()
    const history = useHistory();


    const hideComments = () => {
        setCommentsPopUp(false)
    }
    const showComments = (e, commentsData) => {
        e.stopPropagation()
        setCommentsData(commentsData)
        setCommentsPopUp(true)
    }
    const hideEmailHistory = () => {
        setShowEmailHistoryPopUp(false)
    }
    const showEmailHistory = (e, emailData) => {
        e.stopPropagation()
        setEmailHistory(emailData)
        setShowEmailHistoryPopUp(true)
    }

    const selectRow = (id) => {
        history.push(`/company/${id}`)
    }

    const handleRequestSort = (property) => {
        const newOrderAsc = orderBy === property && !isAsc
        setIsAsc(newOrderAsc)
        setOrderBy(property)
        sortData({orderBy:property, isAsc:newOrderAsc})
    }

    return (
        <React.Fragment>
            <Table size="small">
                <TableHeader 
                classes={classes}
                order={isAsc ? "asc" : "desc"}
                orderBy={orderBy}    
                noSelect={noSelect}
                selectCompany={selectCompany}                
                onRequestSort={handleRequestSort} />
                <TableBody>
                    {companyEmailData.map((item) => (
                        <TableRow hover key={item.id} className={classes.tableRow} onClick={() => { selectRow(item.id) }}>
                            <TableCell>
                                <Checkbox color="primary"
                                    disabled={noSelect}
                                    onClick={(e) => { e.stopPropagation() }}
                                    onChange={() => { selectCompany(item.id) }}
                                    checked={selections.includes(item.id)} />
                            </TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.contact}</TableCell>
                            <TableCell>{item.nyaContact}</TableCell>
                            <TableCell>
                                <span className={item.reportCompleted ? classes.textOK : classes.textError}>
                                    {item.reportCompleted ? "Completed" : "Missing"}
                                </span>
                            </TableCell>
                            <TableCell>
                                {item.comments ? (
                                    <IconButton color="inherit" size='small' onClick={(e) => showComments(e, item.comments)}>
                                        <NotesIcon />
                                    </IconButton>
                                ) : "No"}
                            </TableCell>
                            <TableCell>
                                {(item.email && item.email.length > 0) ? (
                                    <IconButton color="inherit" size='small' onClick={(e) => showEmailHistory(e, item.email)}>
                                        <EmailIcon />
                                    </IconButton>
                                ) : "No"}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <CommentsPopUp open={showCommentsPopUp} handleClose={hideComments} commentsData={commentsData} />
            <EmailHistoryPopUp open={showEmailHistoryPopUp} handleClose={hideEmailHistory} emailData={emailHistory} />
        </React.Fragment>
    );
}

export default CompanyEmailTable