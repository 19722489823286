import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles((theme) => ({
    title: { marginBottom: theme.spacing(2) },
    sectionTitle: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
    subTitle: { marginBottom: theme.spacing(2) },
    textField: { paddingRight: "2rem" },
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),
      }
}));

export default ({ companyData, updateCompany }) => {
    const classes = useStyles()
    const { gustUrl = "" } = companyData

    return (
        <React.Fragment>
            <Grid container direction="row" className={classes.sectionTitle}>
                <Grid item md={6}>
                    <Typography variant="h6" className={classes.subTitle}>Integration</Typography>
                    <TextField label="gustUrl" id="gustUrl" value={gustUrl} fullWidth
                            onChange={(e) => { updateCompany({ "gustUrl": e.target.value }) }} />
                </Grid>
            </Grid>
        </React.Fragment>)
}