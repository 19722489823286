import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import isURL from 'validator/lib/isURL';
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useProvideCTA } from '../../hooks/cta'
import logo from '../../assets/nya_logo.png'
import noPhoto from '../../assets/no_photo.png'
import {experience, interests1, interests2} from '../../data/categories'

const useStyles = makeStyles((theme) => ({
    // container: {width: "100%", minHeight: "100vh"},
    container: {
        padding: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    toolbarMargin: { ...theme.mixins.toolbar },
    input: { display: 'none' },
    section: { paddingTop: theme.spacing(2) },
    entryField: { paddingTop: theme.spacing(1) },
    textOK: { color: "green" },
    textError: { color: "red" },
    spinner: { display: 'flex', justifyContent: 'center' },
    photoCard: {
        maxWidth: 180,
        maxHeight: 180,
        raised: true
    },
    photo: {
        height: 180,
        width: 180,
        margin: -16,
        objectFit: "cover"
    },
    personPhoto: {
        marginRight: 20,
        marginBottom: 20,
        display: "flex",
        alignItems: "center"
    },



    // page: {marginTop:"10erm", }
}));


const CtaPersonProfileUpdatePage = () => {
    const classes = useStyles()
    const { personID } = useParams()
    const [spinner, setSpinner] = useState(false);
    const [memberDisplayName, setMemberDisplayName] = useState('')
    const [memberName, setMemberName] = useState('')
    const [photoURL, setPhotoURL] = useState('')
    const [userMessage, setUserMessage] = useState();
    const [linkedIn, setLinkedIn] = useState('');
    const [memberBio, setMemberBio] = useState('');
    const [memberExperience, setMemberExperience] = useState([]);
    const [memberInterests1, setMemberInterests1] = useState([]);
    const [memberInterests2, setMemberInterests2] = useState([]);
    const [linkedInHelper, setLinkedInHelper] = useState('');




    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const params = new URLSearchParams(useLocation().search);
    const cta = useProvideCTA()


    const validateCallback = ({ success, data = {}, errorMessage }) => {
        console.log(data)
        setSpinner(false)
        if (success) {
            setMemberName(data.name ? data.name : '')
            setMemberDisplayName(data.name ? data.name : '')
            setLinkedIn(data.linkedIn ? data.linkedIn : '')
            setPhotoURL(data.photoURL ? data.photoURL : '')
            setMemberBio(data.bio ? data.bio : '')
            if (data.experience) {
                console.log(data.experience)
                setMemberExperience(data.experience.map((item) => ({title: item})))
            }
            if (data.interests1) {
                console.log(data.interests1)
                setMemberInterests1(data.interests1.map((item) => ({title: item})))
            }
            if (data.interests2) {
                console.log(data.interests2)
                setMemberInterests2(data.interests2.map((item) => ({title: item})))
            }
        } else {
            let errorMessageText = errorMessage || "Dummy Message";
            console.log("There was an error: ", errorMessageText);
            setUserMessage({ isError: true, text: errorMessageText })
        }
    }

    const updateMemberCallback = ({ success, errorMessage }) => {
        setSpinner(false)
        if (success) {
            setUserMessage({ isError: false, text: "Thank you. Our records have been updated with your information." })
        } else {
            let errorMessageText = "Oops, we're sorry but something went wrong: "
            if (errorMessage) {
                errorMessageText += errorMessage 
            } else {
                errorMessageText +=  "Please contact NYA directly"
            }
            console.log(errorMessageText);
            setUserMessage({ isError: true, text: errorMessageText })        }
    };

    // Do once on page load
    useEffect(() => {
        setSpinner(true)
        cta.validateMemberLink({ personID }, validateCallback)
    }, []);

    const postData = () => {
        const personData = {id: personID}
        if (memberExperience) personData["experience"] = memberExperience.map((item) => item.title)
        if (memberInterests1) personData["interests1"] = memberInterests1.map((item) => item.title)
        if (memberExperience) personData["interests2"] = memberInterests2.map((item) => item.title)
        if (memberName) personData["name"] = memberName
        if (linkedIn) personData["linkedIn"] = linkedIn
        if (photoURL) personData["photoURL"] = photoURL
        if (memberBio) personData["bio"] = memberBio

        console.log(personData)
        setSpinner(true)
        cta.postMemberUpdate({ personID, personData }, updateMemberCallback)
    }

    
    const changePhoto = (e) => {
        debugger
        // If no file selected - return
        if (e.target.files.length === 0) {
            return
        }

        // if (!personData.id && !personName) {
        //     setUserMessage1({ isError: true, text: "Please enter a name before uploading logo" })
        //     return
        // }

        console.log(e.target.files[0])
        // Validate file format
        const newPersonPhoto = e.target.files[0]
        let fileName = personID
        if (newPersonPhoto.type === "image/jpeg") {
            fileName += ".jpg"
        } else if (newPersonPhoto.type === "image/png") {
            fileName += ".png"
        } else {
            setUserMessage({ isError: true, text: "Invalid format for photo - please use .jpg or .png" })
            return
        }

        // All good - replace existing photo
        var oFReader = new FileReader();
        oFReader.readAsDataURL(newPersonPhoto);
        oFReader.onload = function (oFREvent) {
            // updatePerson({ "photoURL": oFREvent.target.result }) 
            console.log("Photo updated")
            setPhotoURL(oFREvent.target.result);
        };

        // Now upload to AWS
        cta.uploadMemberPhoto({ personID, fileName, file: newPersonPhoto }, uploadMemberPhotoCallback)

    }

    const uploadMemberPhotoCallback = ({ success, data = {}, errorMessage }) => {
        console.log(data)
        setSpinner(false)
        if (success) {
            setPhotoURL(data.photoURL ? data.photoURL : '')
        } else {
            let errorMessageText = errorMessage || "Dummy Message";
            console.log("There was an error: ", errorMessageText);
            setUserMessage({ isError: true, text: errorMessageText })
        }
    }




    const selectionsValid = (selections) => {
        console.log(selections)
        if (selections.length > 3) {
            console.log("ERROR")
            setUserMessage({text:"Please select a maximum of 3 items", isError:true})
            return false
        }
        setUserMessage()
        return true
    }
    const updateExperience = (selections) => {
        if (selectionsValid(selections)) {
            setMemberExperience(selections)
        }
    }

    const updateInterests1 = (selections) => {
        if (selectionsValid(selections)) {
            setMemberInterests1(selections)
        }
    }

    const updateInterests2 = (selections) => {
        if (selectionsValid(selections)) {
            setMemberInterests2(selections)
        }
    }

    function changeLinkedIn(linkUrl) {
        setLinkedIn(linkUrl)
        // check validity
        if (linkUrl.length === 0 || isURL(linkUrl)) {
            setLinkedInHelper('')
        } else {
            setLinkedInHelper('Invlaid URL')
        }
      }

    return (
        <div>
            <React.Fragment>
                <AppBar position="fixed" color="primary" className={classes.appBar}>
                    <Toolbar>
                        <img src={logo} alt="NYA Logo" className={classes.logo} />
                    </Toolbar>
                </AppBar>
            </React.Fragment>

            <div className={classes.toolbarMargin} />

            <Container className={classes.container}>
                <Paper className={classes.paper}>
                    <Typography variant="h4">NYA Member Profile Update: {memberDisplayName}</Typography>
                    <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.section}>
                        <Grid container direction="column" justify="space-between" alignItems="flex-start" className={classes.section}>
                            <Typography variant="body1">As you are aware New York Angels is re-launching its website. As part of this initiative we are asking each of our members to update their public-facing profile. This includes a recent photograph, and an articulation of:</Typography>
                            <Typography variant="body1">1. Industry sectors you have experience in</Typography>
                            <Typography variant="body1">2. Industry sectors you are currently interested in investing in</Typography>
                            <Typography variant="body1">3. Business models/technologies you are currently interested in investing in, and </Typography>
                            <Typography variant="body1">4. A brief bio (we are suggesting ~3 sentences) that elaborates on any aspect of your professional histpry that you believe will be of interested to a prospective Founder or Partner</Typography>
                        </Grid>
                        <Grid className={classes.section}>
                            <Typography variant="body1">Please enter your name as you would wish to see it as part of our list of members on our website</Typography>
                        </Grid>
                        <Grid item md={12} xs={12} className={classes.entryField}>
                            <TextField variant="outlined" fullWidth label="Mamber Name"
                                value={memberName} onChange={(e) => { setMemberName(e.target.value) }}
                            />
                        </Grid>
                        <Grid container direction="column" className={classes.section}>
                            <Grid item md={12}>
                                <Typography variant="body1">Please upload a recent photo of yourself as you would wish to see it on our website:</Typography>
                            </Grid>
                            <Grid item md={2} xs={12} className={classes.entryField}>
                                <Grid container direction="column" className={classes.personPhoto}>
                                    <Card className={classes.photoCard}>
                                        <CardContent>
                                            <CardMedia htmlFor="photo-upload" component="span">
                                                <div>
                                                    <img src={photoURL ? photoURL : noPhoto} className={classes.photo} htmlFor="photo-upload" />
                                                </div>
                                            </CardMedia>

                                        </CardContent>
                                    </Card>
                                    <input
                                        accept="image/*"
                                        id="photo-upload"
                                        hidden
                                        type="file"
                                        onChange={changePhoto}
                                    />
                                    <label htmlFor="photo-upload">
                                        <Button component="span">Upload Photo</Button>
                                    </label>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container direction="column">
                            <Typography variant="body1">Please indicate the 3 most relevant industry sectors in which you have professional experience:</Typography>
                            <Autocomplete className={classes.entryField}
                                multiple
                                value={memberExperience}
                                onChange={(event, value) => updateExperience(value)}
                                options={experience}
                                disableCloseOnSelect
                                getOptionSelected={(option, value) => option.title === value.title}
                                getOptionLabel={(option) => option.title}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            color="primary"
                                            checkedIcon={checkedIcon}
                                            checked={selected}
                                        />
                                        {option.title}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Experience" placeholder="Experience" />
                                )}
                            />
                        </Grid>

                        <Grid container direction="column" className={classes.section}>
                            <Typography variant="body1">Please indicate your top 3 areas of investment interest by industry sector:</Typography>
                            <Autocomplete className={classes.entryField}
                                multiple
                                value={memberInterests1}
                                onChange={(event, value) => updateInterests1(value)}
                                options={interests1}
                                disableCloseOnSelect
                                getOptionSelected={(option, value) => option.title === value.title}
                                getOptionLabel={(option) => option.title}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            color="primary"
                                            checkedIcon={checkedIcon}
                                            checked={selected}
                                        />
                                        {option.title}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Sector Interests" placeholder="Sector Interests" />
                                )}
                            />
                        </Grid>


                        <Grid container direction="column" className={classes.section}>
                            <Typography variant="body1">Please indicate your top 3 areas of investment interest by business model/technology:</Typography>
                            <Autocomplete className={classes.entryField}
                                multiple
                                value={memberInterests2}
                                onChange={(event, value) => updateInterests2(value)}
                                options={interests2}
                                disableCloseOnSelect
                                getOptionSelected={(option, value) => option.title === value.title}
                                getOptionLabel={(option) => option.title}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            color="primary"
                                            checkedIcon={checkedIcon}
                                            checked={selected}
                                        />
                                        {option.title}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Other Interests" placeholder="Other Interests" />
                                )}
                            />
                        </Grid>

                        <Grid container direction="column" className={classes.section}>
                            <Typography variant="body1">Please validate/enter your public LinkedIn profile:</Typography>
                            <TextField className={classes.entryField}
                                variant="outlined"
                                fullWidth
                                label="LinkedIn"
                                placeholder="Link to LinkedIn Profile"
                                value={linkedIn}
                                type="url"
                                error={!!linkedInHelper}
                                helperText={linkedInHelper}    
                                onChange={(e) => { changeLinkedIn(e.target.value) }}
                            />
                        </Grid>

                        <Grid container direction="column" className={classes.section}>
                            <Typography variant="body1">Please enter a brief bio (see above):</Typography>
                            <TextField className={classes.entryField}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                label="Bio"
                                placeholder="Brief bio"
                                value={memberBio}
                                type="text"
                                onChange={(e) => { setMemberBio(e.target.value) }}
                            />
                        </Grid>

                        <Grid item md={2} className={classes.section}>
                            <Button variant="contained" color="primary" component="span" onClick={postData}>
                                Submit Data
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container>
                        {userMessage && <div className={classes.section}>
                            <Typography variant="h5">
                                <span className={userMessage.isError ? classes.textError : classes.textOK}>
                                    {userMessage.text}
                                </span>
                            </Typography>
                        </div>}

                        {spinner && (
                            <Grid container direction="row" justify="center" alignItems="center" className={classes.section}>
                                <CircularProgress />
                            </Grid>
                        )}

                    </Grid>
                </Paper>
            </Container>
        </div>

    );
}

export default CtaPersonProfileUpdatePage;