import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import TemplateList from '../lists/TemplateList'
import { useEmail } from '../../hooks/email'
import { useAuth } from '../../hooks/auth'


const useStyles = makeStyles((theme) => ({
    container: { padding: theme.spacing(2) },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    title: { marginBottom: theme.spacing(2) }
}));


const EmailTemplateListPage = () => {
    const classes = useStyles()
    const email = useEmail()
    const auth = useAuth()
    const [templateData, setTemplateData] = useState([])
    const [error, setError] = useState('')
    const [spinner, setSpinner] = useState(false)

    useEffect(() => {
        console.log("UseEffect called")
        setSpinner(true)
        email.listTemplates(listCallback)
    }, []);

    // Called when List API returns
    const listCallback = ({ success, unauthorized, errorMessage, data }) => {
        // Check for 401: if unauthorized refresh login - HOC will re-render screen
        if (unauthorized) {
            auth.refreshLogin(({ success }) => {
                console.log("Success: " + success)
                if (!success) {
                    auth.logout()
                }
            })
            return
        }

        setSpinner(false)
        if (success) {
            console.log("Get List OK!");
            console.log(data)
            setTemplateData(data)
        } else {
            let errorMessageText = errorMessage || "Dummy Message";
            console.log("There was an error: ", errorMessageText);
            setError(errorMessageText)
        }
    };


    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <Typography variant="h5">Email Templates</Typography>
                <Grid container>
                    <Grid item md={12}>
                        <TemplateList templateData={templateData} />
                    </Grid>
                </Grid>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {spinner && <CircularProgress />}
                </div>
                {error.length > 0 && <Typography variant="body1">{error}</Typography>}

            </Paper>
        </Container>
    );
}

export default EmailTemplateListPage;