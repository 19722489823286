import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/styles';
import { useAuth } from '../../hooks/auth';

const useStyles = makeStyles((theme) => ({
    container: { display: "flex" },
    largeIcon: {
        fontSize: "2.5rem",
        [theme.breakpoints.down("xs")]: {fontSize: "1.5rem"}
    },
    menu: { background: theme.palette.common.blue, color: "white", borderRadius: 0 },
    menuItem: { opacity: 0.7, "&:hover": { opacity: 1 } }
}));

export default function UserMenuButton({photoURL}) {
    const classes = useStyles()
    const auth = useAuth();

    const logout = () => {
        setAnchorEl(null);
        auth.logout();
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.container}>
            <IconButton fontSize="large"
                className={classes.largeButton}
                color="inherit"
                onClick={handleClick}>
                <Avatar src={photoURL} />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                classes={{ paper: classes.menu }}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
                <MenuItem onClick={handleClose}
                    classes={{ root: classes.menuItem }}
                    component={Link}
                    to={"/user"}
                >My Profile</MenuItem>
                <MenuItem
                    classes={{ root: classes.menuItem }}
                    onClick={logout}
                >Logout</MenuItem>
            </Menu>
        </div>
    );
}
