import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid'
import { usePersonList } from '../../hooks/usePersonList'
import PersonContext from '../../context/PersonContext'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';




const useStyles = makeStyles((theme) => ({
    title: { marginBottom: theme.spacing(2) },
    sectionTitle: { marginTop: theme.spacing(2) },
    subTitle: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
    textField: { paddingRight: "2rem" },
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),
      }
}));

export default ({ companyData = {}, updateCompany }) => {
    const classes = useStyles()
    const { id = "" } = companyData
    const { reportingContactID = "" } = companyData
    const { nyaContactID = "" } = companyData
    const { reportingContact = "" } = companyData
    const { nyaContact = "" } = companyData
    const { reportingContactPhoto = "" } = companyData
    const { nyaContactPhoto = "" } = companyData
    const [{ personList, getPersonList }] = usePersonList({ context: PersonContext })


    // Do once on page load
    useEffect(() => {
        getPersonList()
    }, []);

    const getNYAContactData = (id) => {
        const personRecord = personList.find((item) => item.id === id)
        return { "nyaContactID": id, "nyaContactPhoto": personRecord.photoURL}
    }
    const getReportingContactData = (id) => {
        const personRecord = personList.find((item) => item.id === id)
        return { "reportingContactID": id, "reportingContactPhoto": personRecord.photoURL}
    }


    return (
        <React.Fragment>
            <Grid container direction="row" className={classes.sectionTitle}>
                <Grid item md={6}>
                    <Typography variant="h6">Company Contact</Typography>
                    <Grid container direction="row" className={classes.subTitle} justify="space-evenly">
                        <Grid item md={2}>
                            <Avatar src={reportingContactPhoto} className={classes.large} />
                        </Grid>
                        <Grid item md={9} className={classes.textField}>
                            <InputLabel shrink>company contact</InputLabel>
                            <Select fullWidth onChange={(e) => { updateCompany(getReportingContactData(e.target.value)) }}
                                defaultValue={reportingContactID} value={reportingContactID}>
                                {personList.map(person => {
                                    if (person.companyID === id) {
                                        return (
                                            <option key={person.id} value={person.id}>
                                                {person.givenName} {person.familyName}
                                            </option>
                                        )
                                    }
                                })}
                            </Select>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <Typography variant="h6">NYA Contact</Typography>
                    <Grid container direction="row" className={classes.subTitle} justify="space-evenly">
                        <Grid item md={2}>
                            <Avatar src={nyaContactPhoto} className={classes.large} />
                        </Grid>
                        <Grid item md={9} className={classes.textField}>
                            <InputLabel shrink>nya contact</InputLabel>
                            <Select fullWidth onChange={(e) => { updateCompany(getNYAContactData(e.target.value)) }}
                                defaultValue={nyaContactID} value={nyaContactID}>
                                {personList.map(person => {
                                    if (person.isAngel) {
                                        return (
                                            <option key={person.id} value={person.id}>
                                                {person.givenName} {person.familyName}
                                            </option>
                                        )
                                    }
                                })}
                            </Select>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>)
}