import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MemberEmailTable from '../lists/MemberEmailTable';
import { usePersonList } from '../../hooks/usePersonList'
import { useEmail } from '../../hooks/useEmail'
import PageFooter from '../ui/PageFooter'
import PersonContext from '../../context/PersonContext'
import EmailValidationPage from './EmailValidationPage'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 240,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }

}))

const SendMemberEmailPage = () => {
    const classes = useStyles()
    const history = useHistory()
    const [{ personList, isLoading, userMessage, getMemberList, sortPersonList }] = usePersonList({ context: PersonContext })
    const [{ isLoading: isEmailSending, userMessage: userEmailMessage, sendMemberEmail, testData }] = useEmail()
    const [emailType, setEmailType] = useState('');
    const [testOn, setTestOn] = useState(true);
    const [selectedIndexes, setSelectedIndexes] = useState([])



    // Do once on page load
    useEffect(() => {
        console.log("Calling UseEffect...")
        getMemberList()
    }, []);

    const emailButtonClicked = () => {
        sendMemberEmail({ memberList: selectedIndexes, type: emailType, test: testOn })
    }

    const selectMember = (id) => {
        // Check if general select
        if (!id) {
            if (selectedIndexes.length === personList.length) {
                setSelectedIndexes([])
            } else {
                const newSel = personList.map((item) => item.id)
                setSelectedIndexes(newSel)
            }
        } else {
            // add if not there, remove if exists
            const itemExists = selectedIndexes.indexOf(id) != -1
            let newSel = selectedIndexes
            if (itemExists) {
                newSel = selectedIndexes.filter((item) => item != id)
            } else {
                newSel.push(id)
            }
            setSelectedIndexes([...newSel])
        }
    }

    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <Typography variant="h5">Send Member Emails:</Typography>
                <Grid container alignItems="flex-end">
                    <Grid item lg={3}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="type-select-label">Email Type</InputLabel>
                            <Select labelId="type-select-label" value={emailType}
                                onChange={(e) => { setEmailType(e.target.value) }}>
                                <MenuItem value={"nyaMemberUpdate"}>1. Information Request</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={1}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={!testOn}
                                    onChange={() => { setTestOn(!testOn) }}
                                    color="primary"
                                />
                            }
                            label={testOn ? "Test" : "Live"}
                        />
                    </Grid>

                    <Grid item lg={1}>
                        <Button color="primary" variant="contained" onClick={emailButtonClicked} disabled={selectedIndexes.length == 0 || !emailType}>
                            Send
                        </Button>
                    </Grid>
                </Grid>

                <Grid container>
                    <MemberEmailTable memberData={personList} selections={selectedIndexes} sortData={sortPersonList}
                    selectMember={selectMember}/>
                </Grid>

                <PageFooter userMessage={userMessage} spinner={isLoading} />
                <PageFooter userMessage={userEmailMessage} spinner={isEmailSending} />

                <Backdrop className={classes.backdrop} open={isEmailSending} >
                    <CircularProgress color="inherit" />
                </Backdrop>


            </Paper>
        </Container>

    )
}

export default SendMemberEmailPage