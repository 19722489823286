import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import PageFooter from '../ui/PageFooter'
import BasicCompanyList from '../lists/BasicCompanyList'
import {useCompanyList} from '../../hooks/useCompanyList'
import CompanyContext from '../../context/CompanyContext'

const useStyles = makeStyles((theme) => ({
    container: { padding: theme.spacing(2) },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    title: { marginBottom: theme.spacing(2) },
    textOK: { color: "green" },
    textError: { color: "red" }
}));

const CompanyListPage = () => {
    const classes = useStyles()
    const history = useHistory()
    const [{companyData, isLoading, userMessage, getCompanyList, sortCompanyList}] = useCompanyList({context:CompanyContext})

    useEffect(() => {
        getCompanyList()
    }, []);

    const newCompany = () => {
        console.log("New Company")
        history.push(`/company/new`)
    }

    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.title}>
                    <Grid item>
                        <Typography variant="h5">Company List</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={newCompany}>New Company</Button>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item md={12}>
                        <BasicCompanyList companyList={companyData} sortData={sortCompanyList}/>
                    </Grid>
                </Grid>

                <PageFooter userMessage={userMessage} spinner={isLoading} />

            </Paper>
        </Container>
    )
}

export default CompanyListPage;