import { getPersonListAPI } from '../requests/person'
import { useState, useContext } from 'react'
import moment from 'moment'
import { useAuth } from '../hooks/auth';

export const usePersonList = ({ context }) => {
    const [personList, setPersonList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [userMessage, setUserMessage] = useState()
    const { person, dispatchPerson } = useContext(context)
    const auth = useAuth()

    // Sort Person List
    const sortPersonList = ({orderBy, isAsc}) => {
        console.log(orderBy)
        setPersonList([...personList].sort((a,b) => {
            let A 
            let B
            if (orderBy === 'bio') {
                A = a[orderBy] ? a[orderBy].length : 0
                B = b[orderBy] ? b[orderBy].length : 0
            } else if (typeof a[orderBy] === 'string') {
                A = a[orderBy] ? a[orderBy].toLowerCase() : ""
                B = b[orderBy] ? b[orderBy].toLowerCase() : ""
            } else if (typeof a[orderBy] === 'boolean') {
                A = a[orderBy] ? 1 : 0
                B = b[orderBy] ? 1 : 0
            } else if (typeof a[orderBy] === 'object') {
                A = a[orderBy] ? a[orderBy].length : ""
                B = b[orderBy] ? b[orderBy].length : ""
            } else {
                A = a[orderBy]
                B = b[orderBy]
            }
            
            if (isAsc) {
                if (A > B) return -1
                if (B > A) return 1
            } else {
                if (A > B) return 1
                if (B > A) return -1
            }
            return 0
        }))
    }


    const getMemberList = () => {
        // If we have cached person data - show it
        console.log(person)
        if (person.data && person.data.length > 0) {
            const memberData = person.data.filter((item) => (item.isAngel && item.status === 'active'))
            setPersonList(memberData.sort(((a,b) => {
                if (a.familyName < b.familyName) return -1
                return a.familyName > b.familyName ? 1 : 0
            })))
        } else {
            // Else go to server for data
            setIsLoading(true)
            getPersonListAPI()
                .then((response) => {
                    setIsLoading(false)
                    console.log(response)
                    if ("unauthorized" in response) {
                        setUserMessage({ isError: true, text: "Unauthorized" })
                    } else {
                        if (response.success) {
                            console.log("RESPONSE DATA: ")
                            console.log(response.data)
                            // setPersonList(response.data)
                            dispatchPerson({ type: 'POPULATE_PEOPLE', data: response.data })
                        } else {
                            setUserMessage({ isError: true, text: response.errorMessage })
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false)
                    setUserMessage({ isError: true, text: error })
                })
        }
    }

    // Return Person data from context (if available) or from server
    const getPersonList = () => {
        // If we have cached person data - show it
        if (person) {
            setPersonList(person.data.sort(((a,b) => {
                if (a.familyName < b.familyName) return -1
                return a.familyName > b.familyName ? 1 : 0
            })))
            // if it has been 5 mins since the last server call - refresh data
            if (person.timestamp) {
                const lastRefresh = moment().diff(person.timestamp, 'minutes')
                console.log(`Time since last server refresh ${lastRefresh}`)
                if (lastRefresh < 5) return
            }
        }

        // Else go to server for data
        setIsLoading(true)
        getPersonListAPI()
            .then((response) => {
                setIsLoading(false)
                console.log(response)
                if ("unauthorized" in response) {
                    setUserMessage({ isError: true, text: "Unauthorized" })
                } else {
                    if (response.success) {
                        console.log("RESPONSE DATA: ")
                        console.log(response.data)
                        // setPersonList(response.data)
                        dispatchPerson({ type: 'POPULATE_PEOPLE', data: response.data })
                    } else {
                        setUserMessage({ isError: true, text: response.errorMessage })
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                setUserMessage({ isError: true, text: error })
            })
    }

    return [{ personList, isLoading, userMessage, getPersonList, getMemberList,  sortPersonList }];

}
