import { Route, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Header from '../components/ui/Header';
import {useAuth} from '../hooks/auth';
import { theme } from '../components/ui/theme';

const useStyles = makeStyles(() => {

    return ({
      content: { marginLeft: 200, flexGrow: 1, height: '100vh', overflow: 'auto'},
      contentPhone: { marginLeft: 120, flexGrow: 1, height: '100vh', overflow: 'auto'}
    })
  });


const PrivateRoute = ({ component: Component, ...rest }) => {
    let auth = useAuth();
    let isAuthenticated = !!auth.userAuthenticated;
    const classes = useStyles()
    const isPhone = useMediaQuery(theme.breakpoints.down('xs'))
  
    return (
        <Route {...rest} component={(props) => (
            isAuthenticated ? (
                <main className={isPhone ? classes.contentPhone : classes.content}>
                    <Header />
                    <Component {...props} />
                </main>
            ) : (
                <Redirect to='/' />
            )
        )}/>
    );
}
    

export default PrivateRoute;