import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import Link from '@material-ui/core/Link'
import Avatar from '@material-ui/core/Avatar'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { gridDateTimeFormatter } from '@material-ui/data-grid'

const headerCells = [
    { id: 'name', label: 'Name' },
    { id: 'school', label: 'School' },
    { id: 'year', label: 'Year' },
    { id: 'commitDate', label: 'Date' },
    { id: 'x50 Y Free', label: '50 Free' },
    { id: 'x100 Y Free', label: '100 Free' },
    { id: 'x100 Y Back', label: '100 Back' },
    { id: 'x200 Y IM', label: '200 IM' }]

const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
        border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden',
        padding: 0, position: 'absolute', top: 20, width: 1,
    },
}))


const TableHeader = (props) => {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => () => {
        onRequestSort(property)
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell />
                {headerCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

const getTime = ({ times, event }) => {
    const eventRecord = times.find((item) => item.event === event)
    return eventRecord ? eventRecord.time : ""
}


const SwimmerList = ({ swimmerData = [], sortData }) => {
    const classes = useStyles()
    const history = useHistory()
    const [isAsc, setIsAsc] = useState(false)
    const [orderBy, setOrderBy] = useState()

    const selectRow = (id) => {
        console.log("row selected")
    }

    console.log(swimmerData)

    const handleRequestSort = (property) => {
        console.log(property)
        const newOrderAsc = orderBy === property && !isAsc
        setIsAsc(newOrderAsc)
        setOrderBy(property)
        sortData({ orderBy: property, isAsc: newOrderAsc })
    }

    return (
        <React.Fragment>
            <Table size="small">
                <TableHeader
                    classes={classes}
                    order={isAsc ? "asc" : "desc"}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort} />
                <TableBody>
                    {swimmerData.map((item) => (
                        <TableRow hover key={item.id} onClick={() => { selectRow(item.id) }} >
                            <TableCell>
                                <Avatar alt={item.name} src={item.photoURL} />
                            </TableCell>
                            <TableCell>
                                <Link href={`https://www.swimcloud.com/swimmer/${item.id}/`}>
                                    {item.name}
                                </Link>
                            </TableCell>
                            <TableCell>{item.school.name}</TableCell>
                            <TableCell>{item.year}</TableCell>
                            <TableCell>{item.commitDate}</TableCell>
                            <TableCell>{getTime({ times: item.times, event: '50 Y Free' })}</TableCell>
                            <TableCell>{getTime({ times: item.times, event: '100 Y Free' })}</TableCell>
                            <TableCell>{getTime({ times: item.times, event: '100 Y Back' })}</TableCell>
                            <TableCell>{getTime({ times: item.times, event: '200 Y IM' })}</TableCell>
                        </TableRow>
                    ))}

                </TableBody>
            </Table>
        </React.Fragment>
    );
}

export { SwimmerList as default }