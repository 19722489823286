import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NotesIcon from '@material-ui/icons/Notes'
import IconButton from '@material-ui/core/IconButton';
import EmailIcon from '@material-ui/icons/EmailOutlined'
import ChatIcon from '@material-ui/icons/ChatOutlined'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Link from '@material-ui/core/Link';
import InfoPopUp from '../ui/InfoPopUp'
import EmailHistoryPopUp from '../ui/EmailHistoryPopUp'
import CommentsPopUp from '../ui/CommentsPopUp'
import {downloadFileAPI} from '../../requests/file'

const useStyles = makeStyles((theme) => ({
    tableRow: { textDecoration: 'none' },
    textOK: { color: "green" },
    textError: { color: "red" }
}));


const EmailValidationList = ({ emailData = []}) => {
    const classes = useStyles()

    return (
        <React.Fragment>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Company</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>From</TableCell>
                        <TableCell>To Address</TableCell>
                        <TableCell>CC Address</TableCell>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Contact</TableCell>
                        <TableCell>NYA Contact</TableCell>
                        <TableCell>Period</TableCell>
                        <TableCell>Sender</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {emailData.map((item) => {
                        console.log(item.data.Tags)
                        console.log(item.data.TemplateData)
                        const template = JSON.parse(item.data.TemplateData)

                        return (
                        <TableRow key={item.companyName} className={classes.tableRow}>
                            <TableCell>{item.companyName}</TableCell>
                            <TableCell>{item.data.Template}</TableCell>
                            <TableCell>{item.data.Source}</TableCell>
                            <TableCell>{item.data.Destination.ToAddresses[0]}</TableCell>
                            <TableCell>{item.data.Destination.CcAddresses ? item.data.Destination.CcAddresses[0] : ''}</TableCell>
                            <TableCell>{template.companyName}</TableCell>
                            <TableCell>{template.contactName}</TableCell>
                            <TableCell>{template.nyaContactName}</TableCell>
                            <TableCell>{template.reportingPeriod}</TableCell>
                            <TableCell>{template.senderName}</TableCell>
                        </TableRow>
                    )})}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}

export default EmailValidationList