const DOMAIN = "https://77gztusqbd.execute-api.us-east-1.amazonaws.com/"


const getPersonAPI = ({id, timestamp}) => {
    let url = `${DOMAIN}person?id=${id}`
    if (timestamp) {
        url += `&timestamp=${timestamp}`
    }

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);

    console.log(`Call Get Person API: ${url}`)
    return fetch(url, {method: 'GET', headers: headers}).then((response) => {
        if (response.status === 204) {
            return {noChange:true}
        } 
        if (response.status === 401) {
            return {unauthorized:true}
        } 
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    });
};


const getPersonListAPI = (period) => {
    const url = `${DOMAIN}person/list`

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);

    console.log(`Call Get Person List API: ${url}`)
    return fetch(url, {method: 'GET', headers: headers}).then((response) => {
        if (response.status === 401) {
            return {unauthorized:true}
        } 
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    });
};


const updatePersonAPI = (personData) => {
    const url = `${DOMAIN}person?id=${personData.id}`

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);
    console.log(`Call Post User Update API: ${url}`)

    return fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(personData) })
        .then((response) => {
            if (response.status === 401) {
                // Check for expired token - refresh login
                return { unauthorized: true }
            } else {
                return response.json()
            } 
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}


const createPersonAPI = (personData) => {
    const url = `${DOMAIN}person`

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);
    console.log(`Call Create User API: ${url}`)

    return fetch(url, { method: 'PUT', headers: headers, body: JSON.stringify(personData) })
        .then((response) => {
            if (response.status === 401) {
                // Check for expired token - refresh login
                return { unauthorized: true }
            } else {
                return response.json()
            } 
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export { updatePersonAPI, getPersonListAPI, getPersonAPI, createPersonAPI }
