import React from 'react';
import moment from 'moment'
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CompanyReportingTable from '../lists/CompanyReportingTable'

const useStyles = makeStyles((theme) => ({
    sectionTitle: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }
}));

export default ({ reportingData = {}, updateReporting}) => {
    const classes = useStyles()

    // Convert object into array for display
    let formattedReportingData = []
    for (const [key, value] of Object.entries(reportingData)) {
        const reportLine = {}
        reportLine.period = key
        reportLine.completed = value.confirmed
        if (value.timestamp) reportLine.timestamp = moment(value.timestamp).format("MMM Do, YYYY")
        if (value.reporter) reportLine.reporter = value.reporter
        if (value.reports) {
            reportLine.reports = []
            value.reports.map((item) => {reportLine.reports.push({file: item.file, location: item.fileLocation})})
        } 
        if (value.email) reportLine.email = value.email 
        if (value.comments) reportLine.comments = value.comments 
        if (value.ask) reportLine.ask = value.ask.text
        formattedReportingData.push(reportLine)
    }

    return (
    <Grid container direction="row" className={classes.sectionTitle}>
        <Typography variant="h6">Reporting History</Typography>
        <CompanyReportingTable reportingData={formattedReportingData} updateReporting={updateReporting}/>
    </Grid>)
}










