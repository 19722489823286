import {useState, useContext} from 'react'
import { getCompanyListAPI, getCompanyReportStatusListAPI, getCompanyEmailListAPI } from '../requests/company'
import moment from 'moment'
import { useAuth } from '../hooks/auth';

// Hook for all company Access
export const useCompanyList = ({context}) => {
    const {company, dispatchCompany} = useContext(context)
    const [companyData, setCompanyData] = useState(company.data)
    const [isLoading, setIsLoading] = useState(false)
    const [userMessage, setUserMessage] = useState()
    const auth = useAuth()

    // Sort Company List
    const sortCompanyList = ({orderBy, isAsc}) => {
        setCompanyData([...companyData].sort((a,b) => {
            let A 
            let B
            if (typeof a[orderBy] === 'string') {
                A = a[orderBy] ? a[orderBy].toLowerCase() : ""
                B = b[orderBy] ? b[orderBy].toLowerCase() : ""
            } if (typeof a[orderBy] === 'boolean') {
                A = a[orderBy] ? 1 : 0
                B = b[orderBy] ? 1 : 0
            } else {
                A = a[orderBy]
                B = b[orderBy]
            }

            if (isAsc) {
                if (A > B) return -1
                if (B > A) return 1
            } else {
                if (A > B) return 1
                if (B > A) return -1
            }
            return 0
        }))
    }

    // Return company data from context (if available) or from server
    const getCompanyList = () => {
        // If we have cached company data - show it
        if (company) {
            setCompanyData(company.data.sort(((a,b) => {
                if (a.name < b.name) return -1
                return a.name > b.name ? 1 : 0
            })))
            // if it has been 5 mins since the last server call - refresh data
           if (company.timestamp) {
               const lastRefresh = moment().diff(company.timestamp, 'minutes')
               console.log(`Time since last server refresh ${lastRefresh}`)
               if (lastRefresh < 5) return
           }
        }

        // Else go to server for data
        setIsLoading(true)
        getCompanyListAPI()
            .then((response) => {
                console.log(response)
                setIsLoading(false)
                if ("unauthorized" in response) {
                    console.log("Unauthorized response detected")
                    setUserMessage({ isError: true, text: "unauthorized" })
                } else {
                    if (response.success) {
                        dispatchCompany({type:'POPULATE_COMAPNIES', data: response.data})
                    } else {
                        setUserMessage({ isError: true, text: response.errorMessage })
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                setIsLoading(false)
                setUserMessage({ isError: true, text: error })
            })
    }

    const getCompanyReportStatusList = ({period, confirmed}) => {
        console.log("Period: " + period + " Confrined: " + confirmed)

        // Always grab latest data from server
        setIsLoading(true)
        getCompanyReportStatusListAPI({period, confirmed})
            .then((response) => {
                console.log(response)
                setIsLoading(false)
                if ("unauthorized" in response) {
                    console.log("Unauthorized response detected")
                    setUserMessage({ isError: true, text: "unauthorized" })
                } else {
                    if (response.success) {
                        setCompanyData(response.data)
                    } else {
                        setUserMessage({ isError: true, text: response.errorMessage })
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                setIsLoading(false)
                setUserMessage({ isError: true, text: error })
            })

    }

    const getCompanyEmailList = ({period}) => {
        // Always grab latest data from server
        setIsLoading(true)
        getCompanyEmailListAPI(period)
            .then((response) => {
                console.log(response)
                setIsLoading(false)
                if ("unauthorized" in response) {
                    console.log("Unauthorized response detected")
                    setUserMessage({ isError: true, text: "unauthorized" })
                } else {
                    if (response.success) {
                        setCompanyData(response.data)
                    } else {
                        setUserMessage({ isError: true, text: response.errorMessage })
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                setIsLoading(false)
                setUserMessage({ isError: true, text: error })
            })
    }


    return [{companyData, isLoading, userMessage , getCompanyList, getCompanyReportStatusList, getCompanyEmailList, sortCompanyList }];
}
