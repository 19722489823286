import React, { useState } from 'react';
import { useAuth } from '../hooks/auth';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import nyaLogo from '../assets/NYA.png';
import ChangePasswordModal from './ui/ChangePasswordModal'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.newyorkangels.com/">
        New York Angels
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: { width: "60%" },
  logo2: { width: 300, height: "auto", marginBottom: theme.spacing(3) },
  textOK: { fontWeight:700, fontSize:"1.2rem", textAlign:"center", color: "green" },
  textError: { fontWeight:700, fontSize:"1.2rem", textAlign:"center", color: "red" },
}));

export default function SignIn() {
  const classes = useStyles();

  const auth = useAuth();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [passwordChange, setPasswordChange] = useState({showDialog:false, session:""});
  const [userMessage, setUserMessage] = useState();

  const loginCallback = ({ success, errorMessage, errorType, data }) => {
    console.log("Start Callback on Login screen")
    // setSpinner(false)

    if (success) {
      console.log("Login OK!");
      auth.loginOK();
    } else {
      setSpinner(false)
      console.log(errorType)
      if (errorType === "NEW_PASSWORD_REQUIRED") {
        console.log("new Password required")
        setPasswordChange({showDialog:true, session:data.session})
      } else {
        let errorMessageText = errorMessage || "Dummy Message";
        console.log("There was an error: ", errorMessageText);
        if (errorMessageText.includes("NotAuthorizedException")) {
          errorMessageText = "Invalid login credentials - Please try again"
          // Try and extract message
          const messageArray = errorMessage.split(":")
          if (messageArray.length > 1) {
            errorMessageText = messageArray[1]
          } 
        }
        setUserMessage({isError:true, text:errorMessageText})
      }
    }
  }

  // Called onSubmit to process login request
  const processLogin = (e) => {
    setUserMessage()
    if (!spinner) {
      e.preventDefault();
      setSpinner(true)
      console.log("Calling Login from Login screen")
      auth.login({ userName, password }, loginCallback);
    }
  };

  const closeModal = (message = "")=>{
    console.log("close")
    if (message.length >0) {
      setUserMessage({isError:false, text:message})  
    }
    setPasswordChange({showDialog: false, session:""})
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Box className={classes.logoBox}>
          <img src={nyaLogo} className={classes.logo2} />
        </Box>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={processLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => {setUserName(e.target.value)}}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => {setPassword(e.target.value)}}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          {userMessage && (
            <p className={userMessage.isError ? classes.textError : classes.textOK}>
            {userMessage.text}
          </p>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {spinner ? <CircularProgress size={20} color="inherit" /> : "Sign In"}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
      <ChangePasswordModal userName={userName} open={passwordChange.showDialog} session={passwordChange.session} onClose={closeModal}/>
    </Container>
  );
}