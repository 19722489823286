import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import LaunchIcon from '@material-ui/icons/Launch'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { experience as categoriesExperience } from '../../data/categories'


const useStyles = makeStyles((theme) => ({
    container: { padding: theme.spacing(2) },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    textField: { paddingRight: "2rem" },
    title: { marginBottom: theme.spacing(2) },
    linkButton: { display: 'flex', justifyContent: 'flex-end' },
    entryField: { paddingRight: "2rem" }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


export default ({ personData = {}, updatePerson }) => {
    const classes = useStyles()
    const { bio = "" } = personData
    const { linkedIn = "" } = personData

    let personExperience = []
    if (personData.experience) {
        personExperience = personData.experience.map((item) => ({ title: item }))
    }

    return (
        <div>
            <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <Grid item md={7} xs={12}>
                    <TextField label="bio" id="bio" value={bio} fullWidth
                        className={classes.textField} multiline rowsMax="8"
                        onChange={(e) => { updatePerson({ "bio": e.target.value }) }} />
                </Grid>
                <Grid item md={4} xs={11} >
                    <TextField label="linkedIn" id="linkedIn" value={linkedIn} fullWidth
                        type="url"
                        onChange={(e) => { updatePerson({ "linkedIn": e.target.value }) }} />
                </Grid>
                <Grid item md={1} xs={1} className={classes.linkButton}>
                    <IconButton color="inherit" onClick={() => {
                        window.open(linkedIn, '_blank');
                    }}>
                        <LaunchIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item xl={4} md={7} xs={12}>
                    <Autocomplete className={classes.entryField}
                        multiple
                        value={personExperience}
                        onChange={(event, value) => updatePerson({ "experience": value.map((item) => item.title) })}
                        options={categoriesExperience}
                        disableCloseOnSelect
                        getOptionSelected={(option, value) => option.title === value.title}
                        getOptionLabel={(option) => option.title}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    color="primary"
                                    checkedIcon={checkedIcon}
                                    checked={selected}
                                />
                                {option.title}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField {...params}
                                variant="standard" label="Experience" placeholder="experience" />
                        )}
                    />
                </Grid>
            </Grid>
        </div>
    )
}


