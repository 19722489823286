import moment from 'moment'

const personReducer = (state, action) => {
    console.log("PERSON REDUCER")
    console.log(action)
    console.log(state)

    switch (action.type) {
        case "POPULATE_PEOPLE":
            return { timestamp: moment(), data: action.data }
        case "UPDATE_PERSON":
            console.log("UPDATE_PERSON " + action.data.id)
            // Check person exists
            if (state.data.filter(person => person.id === action.data.id).length > 0) {
                const updatedData = state.data.map((person) => {
                    if (person.id === action.data.id) {
                        console.log("Found Person")
                        return { ...person, ...action.data }
                    } else {
                        return person
                    }
                })
                console.log("New State")
                return { timestamp: state.timestamp, data: updatedData }
            } else {
                console.log("New State - only entry")
                return { timestamp: state.timestamp, data: [action.data] }
            }
        case "NEW_PERSON":
            console.log("NEW_PERSON: " + action.data.id)
            return { timestamp: state.timestamp, data: [...state.data, action.data] }

        case "TESTING":
            return state
        default:
            return state
    }
}

export default personReducer

