const DOMAIN = "https://77gztusqbd.execute-api.us-east-1.amazonaws.com/"

const getDashboardDataAPI = () => {
    const url = `${DOMAIN}dashboard`

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);

    console.log(`Call Get Dashboard Data API: ${url}`)
    return fetch(url, {method: 'GET', headers: headers}).then((response) => {
        if (response.status === 401) {
            return {unauthorized:true}
        } 
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    })
}

export {getDashboardDataAPI}
