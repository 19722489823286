const DOMAIN = "https://77gztusqbd.execute-api.us-east-1.amazonaws.com/"

const getUserListAPI = () => {
    const url = `${DOMAIN}admin/users`

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);

    console.log(`Call Get User List API: ${url}`)
    return fetch(url, {method: 'GET', headers: headers}).then((response) => {
        if (response.status === 401) {
            return {unauthorized:true}
        } 
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    })
}

const createNewUserAPI = ({name, email, personID}) => {
    let url = `${DOMAIN}admin/user?`
    if (personID) {
        url += `personID=${personID}`
    } else {
        url += `name=${name}&email=${email}`
    }

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);

    console.log(`Call Create New User API: ${url}`)
    return fetch(url, {method: 'POST', headers: headers})
    .then((response) => {
        if (response.status === 401) {
            return {unauthorized:true}
        } 
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    })
}

const resetUserPasswordAPI = ({email}) => {
    let url = `${DOMAIN}admin/user/password?userName=${email}`

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', token);

    console.log(`Call Create New User API: ${url}`)
    return fetch(url, {method: 'POST', headers: headers})
    .then((response) => {
        if (response.status === 401) {
            return {unauthorized:true}
        } 
        return response.json()
    })
    .catch((error) => {
        return Promise.reject(error);
    });
};


export { getUserListAPI, resetUserPasswordAPI, createNewUserAPI }
