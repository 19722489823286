import AuthContext from '../context/AuthContext';
import { useContext, useState } from 'react';
import { loginAPI, changePasswordAPI } from '../requests/auth';


export const useAuth = () => {
    return useContext(AuthContext);
}

export const useProvideAuth = () => {
    console.log("get here 2")
    const [userAuthenticated, setUserAuthenticated] = useState(false);
    const [userData, setUserData] = useState();

    // Check for browser refresh
    const checkData = () => {
        if (!userAuthenticated) {
            if (localStorage.getItem('idToken')) {
                setUserAuthenticated(true)
                setUserData(JSON.parse(localStorage.getItem('userData')));
            }
        }
    }

    const loginOK = () => {
        console.log("Call LoginOK")
        setUserAuthenticated(true);
    }

    const refreshLogin = (callBack) => {
        console.log("Calling refresh login")
        const refreshToken = localStorage.getItem('refreshToken')
        if (refreshToken) {
            return callBack({ success: false, errorMessage: "Missing refresh token" })
        }
        return login({refreshToken}, callBack)
    }

    const login = ({ userName, password, refreshToken }, callBack) => {
        console.log("Refresh token #2: " + !!refreshToken)
        loginAPI({ userName, password, refreshToken })
            .then((response) => {
                console.log("Response in Auth Hook")
                console.log(response)
                if (response.success) {
                    // Save Data to local storage
                    localStorage.setItem('userData', JSON.stringify(response.data));
                    localStorage.setItem('idToken', response.data.idToken);
                    localStorage.setItem('refreshToken', response.data.refreshToken);
                    setUserData({ ...response.data })
                    callBack({ success: true });
                } else {
                    callBack({ success: false, errorType: response.errorType, errorMessage: response.errorMessage, data:response.data })
                }
            })
            .catch((error) => {
                callBack({ success: false, errorMessage: error })
            })
    }

    const changePassword = ({userName, password, session}, callBack) => {
        changePasswordAPI({ userName, password, session })
            .then((response) => {
                if (response.success) {
                    callBack({ success: true });
                } else {
                    callBack({ success: false, errorMessage: response.errorMessage })
                }
            })
            .catch((error) => {
                callBack({ success: false, errorMessage: error })
            })
    }

    const logout = () => {
        // Remove from local storage
        localStorage.removeItem('userData');
        localStorage.removeItem('idToken');
        localStorage.removeItem('refreshToken');
        setUserAuthenticated(false);
    }

    const getUserData = () => userData

    const updateUserData = (data) => {
        let newUserData = {...userData, ...data}
        console.log(newUserData)
        setUserData(newUserData)
    }

    return { userAuthenticated, login, logout, loginOK, getUserData, updateUserData, checkData, refreshLogin, changePassword};
}
