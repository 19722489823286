import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from "react-router-dom";
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../../assets/nya_logo.png'
import { useProvideCTA } from '../../hooks/cta';


const useStyles = makeStyles((theme) => ({
    // container: {width: "100%", minHeight: "100vh"},
    container: {
        padding: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    toolbarMargin: { ...theme.mixins.toolbar },

    // page: {marginTop:"10erm", }
}));

const CtaConfirmationPage = () => {
    const classes = useStyles()
    const { companyID } = useParams()
    const [error, setError] = useState('');
    const [spinner, setSpinner] = useState(true);
    const params = new URLSearchParams(useLocation().search);
    const personID = params.get('n')
    const period = params.get('p')
    const cta = useProvideCTA()


    const confirmationCallback = ({ success, errorMessage }) => {
        setSpinner(false)

        if (success) {
            console.log("Check OK!");
        } else {
            let errorMessageText = errorMessage || "Dummy Message";
            console.log("There was an error: ", errorMessageText);
            setError(errorMessageText)
        }
    };

    // Do once on page load
    useEffect(() => {
        console.log("Call API")
        cta.confirmReport({companyID, personID, period}, confirmationCallback)
    }, []);

    return (
        <div>
            <React.Fragment>
                <AppBar position="fixed" color="primary" className={classes.appBar}>
                    <Toolbar>
                        <img src={logo} alt="NYA Logo" className={classes.logo} />
                    </Toolbar>
                </AppBar>
            </React.Fragment>

            <div className={classes.toolbarMargin} />

            <Container className={classes.container}>
                <Paper className={classes.paper}>
                    <Typography variant="h4">{error ? "Error" : "Success"}</Typography>
                    {error.length > 0 && <Typography variant="body1">{error}</Typography>}
                    {error.length > 0 && <Typography variant="body1">If you feel that this is incorrect, please get in touch with us to clarify.</Typography>}
                    {error.length == 0 && <Typography variant="body1">Thank you for confirming that your reports have been sent out. We will update our records accordingly.</Typography>}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {spinner && <CircularProgress />}
                    </div>
                </Paper>
            </Container>
        </div>

    );
}

export default CtaConfirmationPage;