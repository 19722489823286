import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography'
import swimLogo from '../../assets/swimIcon.png'


function ElevationScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

const useStyles = makeStyles((theme) => ({
    toolbarMargin: { ...theme.mixins.toolbar },
    appBar: { zIndex: theme.zIndex.drawer + 1 },
    logo: { marginRight: "1rem",
        height: "2rem", [theme.breakpoints.down("xs")]: { height: "1.5rem" } },
}));



const Header = (props) => {
    const classes = useStyles()
    return (
        <div>
            <React.Fragment>
                <ElevationScroll>
                    <AppBar position="fixed" color="primary" className={classes.appBar}>
                        <Toolbar>
                            <img src={swimLogo} alt="Swim Logo" className={classes.logo} />
                            <Typography variant="h5">SwimRadar</Typography>
                        </Toolbar>
                    </AppBar>
                </ElevationScroll>
            </React.Fragment>
            <div className={classes.toolbarMargin} />
        </div>
    )

}

export default Header;