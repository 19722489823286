const DOMAIN = "https://77gztusqbd.execute-api.us-east-1.amazonaws.com/"

const uploadFileAPI = ({fileName, path, file}) => {
    const url = `${DOMAIN}file?path=${path}&name=${fileName}`

    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    headers.append('Content-Type', file.type);
    headers.append('Authorization', token);
    console.log(`Call Upload File API: ${url}`)

    return fetch(url, { method: 'POST', headers: headers, body: file })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else if (response.status === 401) {
                // Check for expired token - refresh login
                return { unauthorized: true }
            } else if (response.bodyUsed) {
                return response.json()
            } else {
                const errorMessage = 'Looks like there was a problem. Status Code: ' + response.status;
                console.log(errorMessage);
                return Promise.reject(errorMessage);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const downloadFileAPI = ({file, location}) => {
    const url = `${DOMAIN}file?key=${file}&bucket=${location}`
    // Get token from local storage
    const token = localStorage.getItem('idToken')

    const headers = new Headers();
    // headers.append('Content-Type', file.type);
    headers.append('Authorization', token);
    console.log(`Call Upload File API: ${url}`)

    return fetch(url, { method: 'GET', headers: headers})
        .then((response) => {
            if (response.ok) {
                return response.blob()
            } else if (response.status === 401) {
                // Check for expired token - refresh login
                return { unauthorized: true }
            } else {
                const errorMessage = 'Looks like there was a problem. Status Code: ' + response.status;
                console.log(errorMessage);
                return Promise.reject(errorMessage);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export { uploadFileAPI, downloadFileAPI }
