import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { makeStyles } from '@material-ui/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CompanyIcon from '@material-ui/icons/Business';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import MailIcon from '@material-ui/icons/Mail';
import PeopleIcon from '@material-ui/icons/People';
import AccountIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import MUIDrawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import { Hidden } from '@material-ui/core';
import List from '@material-ui/core/List';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton'
import { useAuth } from '../../hooks/auth';
import logo from '../../assets/nya_logo.png'
import UserMenuButton from './UserMenuButton';

const drawerWidth = 200;
const drawerWidthMobile = 120;
const itemsList = [{ text: 'Dashboard', icon: DashboardIcon, path: "/" },
{ text: 'Companies', icon: CompanyIcon, path: "/company/list" },
{ text: 'People', icon: PeopleIcon, path: "/person/list" },
{ text: 'Send Emails', icon: MailIcon, path: "/email/send" },
{ text: 'Members', icon: MailIcon, path: "/email/member/send" },
{ text: 'Templates', icon: MailIcon, path: "/email/templates" },
{ text: 'Admin', icon: SettingsIcon, path: "/admin" },
{ text: 'Users', icon: AccountIcon, path: "/users" }];

function ElevationScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

const useStyles = makeStyles((theme) => ({
    toolbarMargin: { ...theme.mixins.toolbar },
    logo: { height: "2rem", [theme.breakpoints.down("xs")]: { height: "1.5rem" } },
    container: { display: "flex" },
    appBar: { zIndex: theme.zIndex.drawer + 1 },
    drawer: { width: drawerWidth, flexShrink: 0, [theme.breakpoints.down("xs")]: { width: drawerWidthMobile } },
    toolbar: theme.mixins.toolbar,
    drawerPaper: { width: drawerWidth, [theme.breakpoints.down("xs")]: { width: drawerWidthMobile } },
    buttonContainer: {
        marginLeft: "auto", marginRight: "2rem", display: "flex", alignItems: "center",
        [theme.breakpoints.down("xs")]: { marginRight: "0rem" }
    },
    largeButton: {
        // marginLeft: "1.2rem",
        // marginRight: "1.2rem"
    },
    largeIcon: {
        fontSize: "2rem",
        [theme.breakpoints.down("xs")]: { fontSize: "1.2rem" }
    },
    drawerContainer: { overflow: 'auto' },
    drawerItem: { opacity: 0.7 },
    drawerItemSelected: { opacity: 1.0, color: theme.palette.common.blue }
}));



const Header = (props) => {
    const classes = useStyles()
    const [menuSelection, setMenuSelection] = useState(0);
    const [userName, setUserName] = useState("missing name");
    const [userPhoto, setUserPhoto] = useState();
    const [noEmails, setNoEmails] = useState(0)

    const auth = useAuth()

    const handleClick = (index) => {
        setMenuSelection(index)
    }
    useEffect(() => {
        setMenuSelection(itemsList.findIndex((item) => window.location.pathname === item.path));
        const userData = auth.getUserData()
        if (userData) {
            const userName = `${userData.givenName} ${userData.familyName}`
            setUserName(userName)
            if (userData.photoURL) {
                setUserPhoto(userData.photoURL)
            }
        }
    }, []);

    return (
        <div>
            <React.Fragment>
                <ElevationScroll>
                    <AppBar position="fixed" color="primary" className={classes.appBar}>
                        <Toolbar>
                            <Link to="/">
                                <img src={logo} alt="NYA Logo" className={classes.logo} />
                            </Link>
                            <div className={classes.buttonContainer}>
                                <IconButton className={classes.largeButton} fontSize="large" color="inherit">
                                    <Badge badgeContent={noEmails} color="error">
                                        <MailIcon className={classes.largeIcon} />
                                    </Badge>
                                </IconButton>
                                <UserMenuButton photoURL={userPhoto} />
                                <Hidden xsDown>
                                    <Typography variant="h6" >{userName}</Typography>
                                </Hidden>

                            </div>
                        </Toolbar>
                    </AppBar>
                </ElevationScroll>
                <div className={classes.drawerContainer}>
                    <MUIDrawer variant="permanent" anchor="left"
                        classes={{ paper: classes.drawerPaper }}
                        className={classes.drawer}>
                        <div className={classes.toolbar} />
                        <List disablePadding>
                            {itemsList.map((item, index) => (
                                <ListItem
                                    divider
                                    button key={item.text}
                                    component={Link}
                                    to={item.path}
                                    onClick={() => handleClick(index)}
                                    selected={index === menuSelection ? true : false}>
                                    <ListItemIcon>{<item.icon />}</ListItemIcon>
                                    <ListItemText className={index === menuSelection ? classes.drawerItemSelected : classes.drawerItem}>{item.text}
                                    </ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    </MUIDrawer>
                </div>

            </React.Fragment>
            <div className={classes.toolbarMargin} />
        </div>
    )

}

export default Header;