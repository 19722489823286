import React from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles((theme) => ({
    tableRow: { textDecoration: 'none' }
}));


export default ({ templateData = [] }) => {
    const classes = useStyles()
    const history = useHistory()

    const rowClicked = (templateName) => {
        console.log("clicked!" + templateName)

        history.push(`/email/${templateName}`)
        // component={RouterLink} to={
    }

    return (
        <React.Fragment>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Date Created</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {templateData.map((item) => (
                        <TableRow hover key={item.name} onClick={()=>{rowClicked(item.name)}} 
                                 className={classes.tableRow}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{moment(item.email).format("MMM Do, YYYY")}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}