import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import PageFooter from '../ui/PageFooter'
import UserList from '../lists/UserList'
import {useUsers} from '../../hooks/useUsers'
import {useAdmin} from '../../hooks/useAdmin'
import PersonContext from '../../context/PersonContext'

const useStyles = makeStyles((theme) => ({
    container: { padding: theme.spacing(2) },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    title: { marginBottom: theme.spacing(2) },
    textOK: { color: "green" },
    textError: { color: "red" }
}));

const UserAdminPage = () => {
    const classes = useStyles()
    const [showDialog, setShowDialog] = useState(false)
    const [{userList, isLoading, userMessage, getUserList, createNewUser, resetUserPassword}] = useUsers({context:PersonContext})

    useEffect(() => {
        getUserList()
    }, []);

    console.log(userList)

    const newUser = () => {
        setShowDialog(true)
    }

    const onModalExit = ({personID = {}, userName = {}, userEmail = {}}) => {
        setShowDialog(false)
        console.log(personID)
        console.log(userName)
        console.log(userEmail)
        createNewUser({personID, name:userName, email:userEmail})
    }


    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.title}>
                    <Grid item>
                        <Typography variant="h5">User List</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={newUser}>New User</Button>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item md={12}>
                        <UserList userData={userList} showDialog={showDialog} closeDialog={onModalExit} resetUserPassword={resetUserPassword}/>
                    </Grid>
                </Grid>

                <PageFooter userMessage={userMessage} spinner={isLoading} />

            </Paper>
        </Container>
    )
}

export default UserAdminPage;