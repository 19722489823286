import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CommentsTable from '../lists/CommentsTable'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={() => onClose(false)}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ManualClosePopUp = ({ open, data = {}, handleClose }) => {
  const [comment, setComment] = React.useState("");
  const { period } = data

  const exitPopUp = (isSave) => {
    if (isSave) {
      handleClose({comment, period})
    } else {
      handleClose()
    }
  }


  return (
    <div>
      <Dialog onClose={exitPopUp} aria-labelledby="pup-up-title" open={open} fullWidth maxWidth="md">
        <DialogTitle id="pop-up-title" onClose={exitPopUp}>
          Manual Close: Period {period}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Please confirm why you are manually closing period for this company:
          </Typography>
          <TextField label="comment" id="comment" variant="outlined" value={comment} fullWidth autoFocus
            onChange={(e) => { setComment(e.target.value) }} />

        </DialogContent>
        <DialogActions>
          <Button onClick={() => exitPopUp(false)} color="inherit">
            Cancel
          </Button>
          <Button onClick={() => {exitPopUp(true)}} color="primary" disabled={comment.length === 0}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ManualClosePopUp