import React, { useEffect, useContext } from 'react';
import { useParams, useLocation } from "react-router-dom";
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';
import PageFooter from '../ui/PageFooter'
import CompanyReportStatusList from '../lists/CompanyReportStatusList'
import {useCompanyList} from '../../hooks/useCompanyList'
import CompanyContext from '../../context/CompanyContext'
import {displayPeriod} from '../../utils/utils'

const useStyles = makeStyles((theme) => ({
    container: { padding: theme.spacing(2) },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    title: { marginBottom: theme.spacing(2) },
    textOK: { color: "green" },
    textError: { color: "red" }
}));

const CompanyReportStatusPage = () => {
    const classes = useStyles();
    const [{companyData, isLoading, userMessage, getCompanyReportStatusList}] = useCompanyList({context:CompanyContext})
    const params = new URLSearchParams(useLocation().search);
    const confirmed = params.get('confirmed')
    const period = params.get('period')

    useEffect(() => {
        console.log("Period: " + period + " Confrined: " + confirmed)
        getCompanyReportStatusList({period, confirmed})
    }, []);

    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <Typography variant="h5">Company Reporting Status: Period {displayPeriod(period)}</Typography>
                <Grid container>
                    <Grid item md={12}>
                        <CompanyReportStatusList companyList={companyData}/>
                    </Grid>
                </Grid>

                <PageFooter userMessage={userMessage} spinner={isLoading} />

            </Paper>
        </Container>
    );
}

export default CompanyReportStatusPage;