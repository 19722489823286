import { getPersonListAPI, updatePersonAPI, getPersonAPI } from '../requests/person';
import { uploadFileAPI } from '../requests/file';

export const usePerson = () => {

    const getPersonList = (callBack) => {
        getPersonListAPI()
            .then((response) => {
                console.log(response)
                if ("unauthorized" in response) {
                    callBack({ unauthorized: true })
                } else {
                    if (response.success) {
                        callBack({ success: true, data: response.data });
                    } else {
                        callBack({ success: false, errorMessage: response.errorMessage })
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                callBack({ success: false, errorMessage: error })
            })
    };

    const getPersonData = ({id}, callBack) => {
        getPersonAPI(id)
            .then((response) => {
                console.log(response)
                if ("unauthorized" in response) {
                    callBack({ unauthorized: true })
                } else {
                    if (response.success) {
                        callBack({ success: true, data: response.data });
                    } else {
                        callBack({ success: false, errorMessage: response.errorMessage })
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                callBack({ success: false, errorMessage: error })
            })
    };

    const update = (personData, callBack) => {
        console.log(personData)
        updatePersonAPI(personData)
            .then((response) => {
                console.log(response)
                if ("unauthorized" in response) {
                    callBack({ unauthorized: true })
                } else {
                    if (response.success) {
                        callBack({ success: true, data: response.data });
                    } else {
                        callBack({ success: false, errorMessage: response.errorMessage })
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                callBack({ success: false, errorMessage: error })
            })
    };

    const uploadPhoto = ({ fileName, file }, callBack) => {
        uploadFileAPI({ fileName, file, path: "images/avatars" })
            .then((response) => {
                console.log(response)
                if ("unauthorized" in response) {
                    callBack({ unauthorized: true })
                } else {
                    if (response.success) {
                        callBack({ success: true, data: response.fileName });
                    } else {
                        callBack({ success: false, errorMessage: response.errorMessage })
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                callBack({ success: false, errorMessage: error })
            })
    }


    return { update, uploadPhoto, getPersonList, getPersonData };
}
