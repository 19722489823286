import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
}));


const CommentsList = ({ commentsData = [] }) => {
    const classes = useStyles()

    return (
        <React.Fragment>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Person</TableCell>
                        <TableCell>Comment</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {commentsData.map((item, idx) => (
                        <TableRow key={idx} className={classes.tableRow}>
                        <TableCell>{moment(item.timestamp).local().format("MMM Do, YYYY - h:mm a")}</TableCell>
                        <TableCell>{item.reporter}</TableCell>
                        <TableCell>{item.text}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}

export default CommentsList