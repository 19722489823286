import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { useAuth } from '../../hooks/auth';
import noPhoto from '../../assets/no_photo.png'


const useStyles = makeStyles((theme) => ({
    container: { padding: theme.spacing(2) },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    photoCard: {
        maxWidth: 180,
        maxHeight: 180,
        raised: true
    },
    photo: {
        height: 180,
        width: 180,
        margin: -16,
        objectFit: "cover"
    },
    personPhoto: {
        marginRight: 20,
        marginBottom: 20,
        display: "flex",
        alignItems: "center"
    },
    textField: { paddingRight: "2rem", paddingLeft: "1rem" },
    title: { marginBottom: theme.spacing(2) },
    button: { display: "flex", alignItems: "center" },
    textOK: { color: "green" },
    textError: { color: "red" },
    userMessage: { marginTop: theme.spacing(2) }
}));




export default ({ personData = {}, updatePerson, uploadPhoto }) => {
    const classes = useStyles()

    const { personName = "" } = personData
    const { address = "" } = personData
    const { email = "" } = personData
    const { phone = "" } = personData
    const { city = "" } = personData
    const { zip = "" } = personData
    const { state = "" } = personData
    const { photoURL = noPhoto } = personData

    const [emailHelper, setEmailHelper] = useState('');
    const [phoneHelper, setPhoneHelper] = useState('');

    const [userMessage1, setUserMessage1] = useState();
    const auth = useAuth()


    // // check for update photo
    // if (fileURL && fileURL != photoURL) {
    //     updatePerson({ "photoURL": fileURL })
    // }

    const onChange = (e) => {
        let isValid;
        switch (e.target.id) {
            case 'email':
                // setEmail(e.target.value)
                isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/.test(e.target.value)
                updatePerson({ "email": e.target.value })
                if (!isValid) {
                    setEmailHelper("Invalid email")
                } else {
                    setEmailHelper('')
                }
                break;
            case 'phone':
                // setPhone(e.target.value)
                isValid = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(e.target.value)
                updatePerson({ "phone": e.target.value })
                if (!isValid) {
                    setPhoneHelper("Invalid phone number")
                } else {
                    setPhoneHelper('')
                }
                break;
            default:
                break;
        }
    }


    const changePhoto = (e) => {
        // If no file selected - return
        if (e.target.files.length === 0) {
            return
        }

        if (!personData.id && !personName) {
            setUserMessage1({ isError: true, text: "Please enter a name before uploading logo" })
            return
        }

        console.log(e.target.files[0])
        // Validate file format
        const newPersonPhoto = e.target.files[0]
        let fileName = personData.id ? personData.id : personData.personName.replaceAll(" ", "")
        if (newPersonPhoto.type === "image/jpeg") {
            fileName += ".jpg"
        } else if (newPersonPhoto.type === "image/png") {
            fileName += ".png"
        } else {
            setUserMessage1({ isError: true, text: "Invalid format for photo - please use .jpg or .png" })
            return
        }

        // All good - replace existing photo
        var oFReader = new FileReader();
        oFReader.readAsDataURL(newPersonPhoto);
        oFReader.onload = function (oFREvent) {
            updatePerson({ "photoURL": oFREvent.target.result }) 
            // setPhotoURL(oFREvent.target.result);
        };

        // Now upload to AWS
        uploadPhoto({ fileName, file: newPersonPhoto })

    }

    return (
        <div>
            <Grid container direction="row">
                <Grid item md={2} xs={12}>
                    <Grid container direction="column" className={classes.personPhoto}>
                        <Card className={classes.photoCard}>
                            <CardContent>
                                <CardMedia htmlFor="photo-upload" component="span">
                                    <div>
                                        <img src={photoURL} className={classes.photo} htmlFor="photo-upload" />
                                    </div>
                                </CardMedia>

                            </CardContent>
                        </Card>
                        <input
                            accept="image/*"
                            id="photo-upload"
                            hidden
                            type="file"
                            onChange={changePhoto}
                        />
                        <label htmlFor="photo-upload">
                            <Button component="span">Change Photo</Button>
                        </label>
                    </Grid>
                </Grid>



                <Grid item md={5} xs={12} className={classes.textField}>
                    <Grid item>
                        <TextField label="name" id="name" value={personName} fullWidth
                            onChange={(e) => {
                                updatePerson({ "personName": e.target.value })
                            }} />
                    </Grid>
                    <Grid item>
                        <TextField label="email" id="email" value={email} fullWidth
                            error={!!emailHelper}
                            helperText={emailHelper}
                            onChange={onChange} />
                    </Grid>
                    <Grid item>
                        <TextField label="phone" id="phone" value={phone} fullWidth
                            error={!!phoneHelper}
                            helperText={phoneHelper}
                            onChange={onChange} />
                    </Grid>

                </Grid>
                <Grid item md={5} xs={12}>
                    <Grid item>
                        <TextField label="address" id="address" value={address} fullWidth
                            onChange={(e) => { updatePerson({ "address": e.target.value }) }} />
                    </Grid>
                    <Grid item>
                        <TextField label="city" id="city" value={city} fullWidth
                            onChange={(e) => { updatePerson({ "city": e.target.value }) }} />
                    </Grid>
                    <Grid item>
                        <TextField label="zip" id="zip" value={zip} fullWidth
                            onChange={(e) => { updatePerson({ "zip": e.target.value }) }} />
                    </Grid>
                    <Grid item>
                        <InputLabel shrink>State</InputLabel>
                        <Select fullWidth onChange={(e) => { updatePerson({ "state": e.target.value }) }} value={state}>
                            <option value=""></option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </Select>
                    </Grid>
                </Grid>
            </Grid>
            {userMessage1 && <div className={classes.section}>
                <Typography variant="h5">
                    <span className={userMessage1.isError ? classes.textError : classes.textOK}>
                        {userMessage1.text}
                    </span>
                </Typography>
            </div>}
        </div>

    )
}


