import React, {useState} from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import Avatar from '@material-ui/core/Avatar'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'

const headerCells = [
    { id: 'givenName', label: 'Name' },
    { id: 'isAngel', label: 'Type' },
    { id: 'companyName', label: 'Company' },
    { id: 'location', label: 'Location' },
    { id: 'email', label: 'Email' },
    { id: 'status', label: 'Status' },
  ]

const useStyles = makeStyles((theme) => ({
    visuallyHidden: { border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden',
                    padding: 0, position: 'absolute', top: 20, width: 1,},
}))

const makeLocation = (location, city, state) => {
    if (location) return location
    if (city && state) return `${city}, ${state}`
    if (city) return `${city}`
    if (state) return `${state}`
    return ''
}

const TableHeader = (props) => {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => () => {
        onRequestSort(property)
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell/>
          {headerCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
}

export default ({ personData = [], sortData }) => {
    const classes = useStyles()
    const history = useHistory()
    const [isAsc, setIsAsc] = useState(false)
    const [orderBy, setOrderBy] = useState()

    const selectRow = (id) => {
        history.push(`/person/${id}`)
    }

    console.log(personData)

    const handleRequestSort = (property) => {
        const newOrderAsc = orderBy === property && !isAsc
        setIsAsc(newOrderAsc)
        setOrderBy(property)
        sortData({orderBy:property, isAsc:newOrderAsc})
    }

    return (
        <React.Fragment>
            <Table size="medium">
                <TableHeader
                    classes={classes}
                    order={isAsc ? "asc" : "desc"}
                    orderBy={orderBy}                    
                    onRequestSort={handleRequestSort} />
                <TableBody>
                    {personData.map((item) => (
                        <TableRow hover key={item.id} onClick={() => { selectRow(item.id) }} >
                            <TableCell>
                                <Avatar alt={item.name} src={item.photoURL} />
                            </TableCell>
                            <TableCell>{item.givenName} {item.familyName}</TableCell>
                            <TableCell>{item.isAngel ? "Angel" : "Entrepreneur"}</TableCell>
                            <TableCell>{item.companyName}</TableCell>
                            <TableCell>{makeLocation(item.location, item.city, item.state)}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{item.status && item.status[0].toUpperCase() + item.status.slice(1)}</TableCell>
                        </TableRow>
                    ))}

                </TableBody>
            </Table>
        </React.Fragment>
    );
}