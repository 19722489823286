import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import PageFooter from '../ui/PageFooter'
import SwimmerList from './SwimmerList'

const DOMAIN = "https://77gztusqbd.execute-api.us-east-1.amazonaws.com/swim"

const useStyles = makeStyles((theme) => ({
    container: { padding: theme.spacing(2) },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    }
}));

const convertTime = (timeString) => {
    if (timeString.includes(":")) {
        const timeArray = timeString.split(":")
        const minutes = parseInt(timeArray[0])
        const seconds = parseFloat(timeArray[1])
        return (minutes*60 + seconds)
    } else {
        return parseFloat(timeString)
    }
}

const SwimPage = () => {
    const classes = useStyles()
    const [swimmerData, setSwimmerData] = useState([])
    const [userMessage, setUserMessage] = useState()
    const [isLoading, setIsLoading] = useState(false)

    // Do once on page load
    useEffect(() => {
        console.log("Calling UseEffect...")
        getData()
    }, [])

    const getData = async () => {
        console.log("getting data")
        setIsLoading(true)
        const response = await fetch(DOMAIN)
        if (response.status === 200) {
            const jsonResponse = await response.json()
            console.log(jsonResponse)
            if (jsonResponse.success) {
                setSwimmerData(jsonResponse.data)
            } else {
                setUserMessage({ isError: true, text: `Error: ${jsonResponse.errorMessage}` })
            }
        } else {
            setUserMessage({ isError: true, text: `Error status ${response.status}` })
        }
        setIsLoading(false)
    }

    const sort = ({ orderBy, isAsc }) => {
        console.log(orderBy)

        setSwimmerData([...swimmerData].sort((a, b) => {
            let a_event = ""
            let b_event = ""
            if (orderBy === "school") {
                if (a[orderBy] && a[orderBy].name) {
                    a_event = a[orderBy].name.toLowerCase()
                }
                if (b[orderBy] && b[orderBy].name) {
                    b_event = b[orderBy].name.toLowerCase()
                }
            } else if (orderBy.charAt(0) === "x") {
                let event = orderBy.replace("x", "")
                if (a.times) {
                    const eventTime = a.times.find((item) => item.event === event)
                    a_event = eventTime ? convertTime(eventTime.time) : 999999
                }
                if (b.times) {
                    const eventTime = b.times.find((item) => item.event === event)
                    b_event = eventTime ? convertTime(eventTime.time) : 999999
                }
            } else {
                a_event = a[orderBy] ? a[orderBy].toLowerCase() : ""
                b_event = b[orderBy] ? b[orderBy].toLowerCase() : ""
            }


            if (a_event === b_event) return 0
            if (isAsc) {
                return (a_event > b_event) ? -1 : 1
            } else {
                return (a_event > b_event) ? 1 : -1
            }
        }))
    }
    
    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.title}>
                    <Grid item>
                        <Typography variant="h5">Recruited Swimmers</Typography>
                    </Grid>
                </Grid>
                <SwimmerList swimmerData={swimmerData} sortData={sort} />
                <PageFooter userMessage={userMessage} spinner={isLoading} />
            </Paper>
        </Container>
    )
}

export default SwimPage;