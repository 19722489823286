import React, { useState } from 'react';
import { useAuth } from '../hooks/auth';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import nyaLogo from '../assets/NYA.png';
import { changePasswordAPI } from '../requests/auth';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.newyorkangels.com/">
        New York Angels
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: { width: "60%" },
  logo2: { width: 300, height: "auto", marginBottom: theme.spacing(3) },
  error: {fontWeight:700, fontSize:"1.2rem", textAlign:"center"}
}));

export default () => {
  const classes = useStyles();

  const auth = useAuth();
  const [userName, setUserName] = useState('simon@oldredpanda.com')
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState('');

  const loginCallback = ({ success, errorMessage }) => {
    console.log("Start Callback on Login screen")
    // setSpinner(false)

    if (success) {
      console.log("Login OK!");
      auth.loginOK();
    } else {
      setSpinner(false)
      let errorMessageText = errorMessage || "Dummy Message";
      console.log("There was an error: ", errorMessageText);
      if (errorMessageText.includes("NotAuthorizedException")) {
        errorMessageText = "Invalid login credentials - Please try again"
      }
      setError(errorMessageText)
    }
  };

  // Called onSubmit to process login request
  const processPasswordReset = (e) => {
    if (!spinner) {
        e.preventDefault();
        setSpinner(true)
        console.log("Calling Login from Login screen")
    //   auth.login({ userName }, passwordResetCallback);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Box className={classes.logoBox}>
          <img src={nyaLogo} className={classes.logo2} />
        </Box>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <form className={classes.form} noValidate onSubmit={processPasswordReset}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => {setUserName(e.target.value)}}
          />
          {error.length>0 && <Typography color={"error"} className={classes.error}>{error}</Typography>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {spinner ? <CircularProgress size={20} color="inherit" /> : "Reset"}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/" variant="body2">
                Back to Login Page
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}