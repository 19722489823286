// Convert Period to human readable form
const displayPeriod = (period) => {
    if (period) {
        const periodArray = period.split("-")
        if (Array.isArray(periodArray) && periodArray.length == 2) {
            const periodText = "Q" + periodArray[1] + ", " + periodArray[0]
            return periodText
        }
    }
    return ""
}

export {displayPeriod}