import React, { useEffect, useReducer } from 'react';
import AppRouter from '../routers/AppRouter';
import { useProvideAuth } from '../hooks/auth';
import AuthContext from '../context/AuthContext';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './ui/theme';
import CssBaseline from "@material-ui/core/CssBaseline";
import CompanyContext from '../context/CompanyContext'
import PersonContext from '../context/PersonContext'
import personReducer from '../reducers/personReducer'
import companyReducer from '../reducers/companyReducer'

// Set up authentication
const ProvideAuth = ({ children, auth }) => (
    <AuthContext.Provider value={auth}>
        {children}
    </AuthContext.Provider>
)

const App = () => {
    const auth = useProvideAuth();
    const [company, dispatchCompany] = useReducer(companyReducer, { timestamp: undefined, data: [] })
    const [person, dispatchPerson] = useReducer(personReducer, { timestamp: undefined, data: [] })
    console.log("STARTING APP>>>>>>")

    useEffect(() => {
        // Check for a browser refresh
        auth.checkData()
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <ProvideAuth auth={auth}>
                <CompanyContext.Provider value={{ company, dispatchCompany }} >
                    <PersonContext.Provider value={{ person, dispatchPerson }}>
                        <AppRouter />
                    </PersonContext.Provider>
                </CompanyContext.Provider>
            </ProvideAuth>
        </ThemeProvider>
    )

}
export default App;
