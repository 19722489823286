import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Checkbox from '@material-ui/core/Checkbox'
import { interests1 as categoriesInterests1 } from '../../data/categories'
import { interests2 as categoriesInterests2 } from '../../data/categories'


const useStyles = makeStyles((theme) => ({
    container: { padding: theme.spacing(2) },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    textField: { paddingRight: "2rem" },
    title: { marginBottom: theme.spacing(2) },
    entryField: { paddingRight: "2rem" }
}))

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />


export default ({ personData = {}, updatePerson }) => {
    const classes = useStyles()
    let personInterests1 = []
    if (personData.interests1) {
        personInterests1 = personData.interests1.map((item) => ({ title: item }))
    }
    let personInterests2 = []
    if (personData.interests2) {
        personInterests2 = personData.interests2.map((item) => ({ title: item }))
    }

    return (
        <div>
            <Grid container direction="row">
                <Grid item xl={4} md={7} xs={12}>
                    <Autocomplete className={classes.entryField}
                        multiple
                        value={personInterests1}
                        onChange={(event, value) => updatePerson({ "interests1": value.map((item) => item.title) })}
                        options={categoriesInterests1}
                        disableCloseOnSelect
                        getOptionSelected={(option, value) => option.title === value.title}
                        getOptionLabel={(option) => option.title}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    color="primary"
                                    checkedIcon={checkedIcon}
                                    checked={selected}
                                />
                                {option.title}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField {...params}
                                variant="standard" label="Interests: Sector" placeholder="sector" />
                        )}
                    />
                </Grid>
                <Grid item xl={4} md={5} xs={12}>
                    <Autocomplete className={classes.entryField}
                        multiple
                        value={personInterests2}
                        onChange={(event, value) => updatePerson({ "interests2": value.map((item) => item.title) })}
                        options={categoriesInterests2}
                        disableCloseOnSelect
                        getOptionSelected={(option, value) => option.title === value.title}
                        getOptionLabel={(option) => option.title}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    color="primary"
                                    checkedIcon={checkedIcon}
                                    checked={selected}
                                />
                                {option.title}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField {...params}
                                variant="standard"
                                placeholder="Interests: Bus Model/Technology"
                                label="Bus Model/Technology" />
                        )}
                    />
                </Grid>
            </Grid>
        </div>
    )
}


