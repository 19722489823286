import { validateLinkAPI, validateMemberLinkAPI, makeAskAPI, uploadReportAPI, uploadMemberPhotoAPI, postMemberUpdateAPI, confirmReportAPI, postCommentAPI, postContactAPI } from '../requests/cta';
export const useProvideCTA = () => {

    const confirmReport = ({ companyID, personID, period }, callBack) => {
        confirmReportAPI({ companyID, personID, period })
            .then((response) => {
                console.log(response)
                if (response.success) {
                    callBack({ success: true });
                } else {
                    callBack({ success: false, errorMessage: response.errorMessage })
                }
            })
            .catch((error) => {
                console.log(error)
                callBack({ success: false, errorMessage: error })
            })
    }


    const validateMemberLink = ({ personID }, callBack) => {
        validateMemberLinkAPI({ personID })
            .then((response) => {
                console.log(response)
                if (response.success) {
                    callBack({ success: true, data: response.data });
                } else {
                    callBack({ success: false, errorMessage: response.errorMessage, data: response.data })
                }
            })
            .catch((error) => {
                console.log(error)
                callBack({ success: false, errorMessage: error })
            })
    };

    const validateLink = ({ companyID, personID, period }, callBack) => {
        validateLinkAPI(companyID, personID, period)
            .then((response) => {
                console.log(response)
                callBack({ success: response.success, data: response.data, errorMessage: response.errorMessage });
                // if (response.success) {
                // } else {
                //     callBack({ success: false, errorMessage: response.errorMessage, data: response.data })
                // }
            })
            .catch((error) => {
                console.log(error)
                callBack({ success: false, errorMessage: error })
            })
    };

    const postComment = ({ companyID, personID, period, text }, callBack) => {
        postCommentAPI(companyID, personID, period, text)
            .then((response) => {
                console.log(response)
                if (response.success) {
                    callBack({ success: true });
                } else {
                    callBack({ success: false, errorMessage: response.errorMessage })
                }
            })
            .catch((error) => {
                console.log(error)
                callBack({ success: false, errorMessage: error })
            })
    };

    const postMemberUpdate = ({personID, personData}, callBack) => {
        postMemberUpdateAPI({personID, personData})
            .then((response) => {
                console.log(response)
                if (response.success) {
                    callBack({ success: true });
                } else {
                    callBack({ success: false, errorMessage: response.errorMessage })
                }
            })
            .catch((error) => {
                console.log(error)
                callBack({ success: false, errorMessage: error })
            })
    };

    const postContact = ({ companyID, email, name, title }, callBack) => {
        postContactAPI({ companyID, email, name, title })
            .then((response) => {
                console.log(response)
                if (response.success) {
                    callBack({ success: true });
                } else {
                    callBack({ success: false, errorMessage: response.errorMessage })
                }
            })
            .catch((error) => {
                console.log(error)
                callBack({ success: false, errorMessage: error })
            })
    };

    const makeAsk = ({ companyID, personID, period, text }, callBack) => {
        makeAskAPI(companyID, personID, period, text)
            .then((response) => {
                console.log(response)
                if (response.success) {
                    callBack({ success: true });
                } else {
                    callBack({ success: false, errorMessage: response.errorMessage })
                }
            })
            .catch((error) => {
                console.log(error)
                callBack({ success: false, errorMessage: error })
            })
    };

    const uploadReport = ({ companyID, period, personID, fileName, file }, callBack) => {
        uploadReportAPI({ fileName, file, personID, path: `reports/${companyID}/${period}`, period, companyID })
            .then((response) => {
                console.log(response)
                if (response.success) {
                    callBack({ success: true, data: response.fileName });
                } else {
                    callBack({ success: false, errorMessage: response.errorMessage })
                }
            })
            .catch((error) => {
                console.log(error)
                callBack({ success: false, errorMessage: error })
            })
    }

    const uploadMemberPhoto = ({ personID, fileName, file }, callBack) => {
        uploadMemberPhotoAPI({ fileName, file, personID })
            .then((response) => {
                console.log(response)
                if (response.success) {
                    callBack({ success: true, data: response.data });
                } else {
                    callBack({ success: false, errorMessage: response.errorMessage })
                }
            })
            .catch((error) => {
                console.log(error)
                callBack({ success: false, errorMessage: error })
            })
    }

    return { confirmReport, validateLink, makeAsk, uploadReport, postComment, postContact, validateMemberLink, uploadMemberPhoto, postMemberUpdate };
}
